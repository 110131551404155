import { ProjectUI } from "../dataModel/ProjectUI";
import { Project } from "@adsk/alloy-top-navigation/es/helpers/types";
import Default_Project from '../assets/Default_Project.png';
import { ProjectType } from "../clients/Client";

export class ConvertAutodeskProject {
    public static GetAutodeskProject(project: ProjectUI): Project {
        let platform: string = 'bim360';
        let platformDisplay: string = 'Unknown';
        switch (project.ProjectType) {
            case ProjectType.Unknown:
                // Nothing to do here.
                break;
            case ProjectType.ACC:
                platform = 'acc';
                platformDisplay = 'Autodesk Docs';
                break;
            case ProjectType.A360:
                platformDisplay = 'A360 Team';
                break;
            case ProjectType.B360Team:
                platformDisplay = 'BIM 360 Team';
                break;
            case ProjectType.B360Docs:
                platformDisplay = 'BIM 360 Docs';
                break;
        }
        return {
            id: project.Id,
            name: project.Name,
            accountName: `${project.HubName} (${platformDisplay})`,
            imageUrl: project.ImagePath ?? Default_Project,
            defaultUrl: '',
            platform: platform,
            classification: 'production',
        };
    }
}