import React, { useEffect } from 'react';
import './App.css';
import MainContent from "./components/MainContent";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NotFound from "./pages/NotFound";
import { Redirect } from "react-router";
import { PAGES, PATHS } from "./Constants";
import Door from "./pages/Door";
import Landing from "./pages/Landing";
import ProtectedRoute from "./components/routing/ProtectedRoute";

const App = () => {
    useEffect(() => {
        if (process.env.REACT_APP_HEADER_TAG != null && process.env.REACT_APP_HEADER_TAG !== '') {
            document.title = `${document.title} | ${process.env.REACT_APP_HEADER_TAG}`;
        }
    }, []);

    return (
        <Router>
            <Switch>
                <ProtectedRoute path={PATHS[PAGES.ROOT]} component={<MainContent />} />
                <Route path={PATHS[PAGES.DOOR]} component={Door} />
                <Route path={`${PATHS[PAGES.LANDING]}`} component={Landing} />
                <Redirect to={PATHS[PAGES.DOOR]} from={''} />
                <Route path="*" component={NotFound} />
            </Switch>
        </Router>
    );
}

export default App;
