// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

export class CustomerUI {
    ID: string;
    Name: string;
    EmailDomain: string;
    AllowAllUsers: boolean;
    ValidEmails: string[];

    constructor(id: string, name: string, domain: string, allowAllUsers: boolean) {
        this.ID = id;
        this.Name = name;
        this.EmailDomain = domain;
        this.AllowAllUsers = allowAllUsers;
        this.ValidEmails = [];
    }
}
