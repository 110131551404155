import { Task } from "../dataModel/Task";

export class ConvertRunDate {
    public static Convert(value: Date | undefined, args?: any, includeTime?: boolean): string {
        if (args != null && args instanceof Task && args.Trigger === 'OnPublish') {
            return 'On File Update';
        }

        if (value == null) {
            return 'n/a';
        } else {
            const formatter = new Intl.DateTimeFormat('un-US', {
                dateStyle: 'medium',
                timeStyle: includeTime ? 'medium' : undefined
            });
            return formatter.format(value);
        }
    }
}