import { JobStatusType } from "../clients/Client";

export class ConvertTaskStatus {
    public static Convert(value: JobStatusType, isOnPublish: boolean = false): string {
        if (isOnPublish) {
            return 'Monitoring';
        }
        switch (value) {
            case JobStatusType.PartiallyCompleted:
                return 'Partially Complete';
            case JobStatusType.PostProcessing:
                return 'Post Processing';
            case JobStatusType.None:
                return 'None';
            case JobStatusType.Scheduled:
                return 'Scheduled';
            case JobStatusType.Running:
                return 'Running';
            case JobStatusType.Paused:
                return 'Paused';
            case JobStatusType.Completed:
                return 'Completed';
            case JobStatusType.Error:
                return 'Error';
        }

        return 'Unknown Status';
    }
}