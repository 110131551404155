// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

import { FileUI } from './FileUI';
import { BIM360ItemBase } from './BIM360ItemBase';
import { HubType, ProjectType } from '../clients/Client';

export class DirectoryUI implements BIM360ItemBase {
    // Interface Implementation
    public IsFolder = true;

    Id: string;
    ParentId: string | undefined;
    ProjectId: string;
    Name: string;
    LastChange: Date | undefined;

    // Class Properties
    Subfolders: DirectoryUI[];
    Models: FileUI[];
    Parent: DirectoryUI | undefined;

    HubId: string | undefined;
    HubRegion: string | undefined;
    HubType: HubType;
    ProjectType: ProjectType;

    Icon: string;

    AreItemsPopulated = false;

    Recursive = false;

    constructor(id: string, name: string, projectId: string) {
        this.Id = id;
        this.Name = name;
        this.ProjectId = projectId;
        this.Subfolders = [];
        this.Models = [];
        this.HubType = HubType.Unknown;
        this.ProjectType = ProjectType.Unknown;
        this.Icon = 'assets/images/Icon_Folder.png';
    }

    get SubItems(): (FileUI | DirectoryUI)[] {
        const newArray: (FileUI | DirectoryUI)[] = [];
        this.Subfolders.forEach(f => newArray.push(f));
        this.Models.forEach(m => newArray.push(m));

        return newArray;
    }
}
