import React, { useReducer } from 'react';
import { UsageDataService } from "../services/UsageDataService";
import { reducer } from "../components/reducers/UsageReducer";
import { UsageState } from "../components/states/UsageState";
import {
    BlueButton, CenteringContainer,
    ColumnLeft,
    ColumnRight,
    ContentWrapper,
    FlexColumn,
    FlexFill,
    FlexRow,
    FlexRowCentered
} from '../CommonStyledComponents';
import {
    ArrowRotateTwoIcon, Checkbox,
    CloudDownArrowIcon,
    DatePicker, Illustration, ProgressRing,
    Theme
} from "@adsk/alloy-react";
import UsageDataTree from "../components/UsageDataTree";
import { UsageActions } from "../Enums";
import IdInput from "../components/IdInput";
import {DownloadUrl, GetErrorMessage, GetFileNameFormattedDate} from '../Utility';
import { ConvertDuration } from "../converters/ConvertDuration";

const service = new UsageDataService();

const Usage = () => {
    const [state, dispatch] = useReducer(reducer, new UsageState());

    function downloadRaw(): void {
        dispatch({ type: UsageActions.loadingCsv, payload: true });
        const startTime = new Date();
        service.StartCsvExport().then(
            dto => {
                console.log(`Started csv export with id ${dto.id}`);
                const timerId = setInterval(() => {
                    service.GetCsvDownload(dto.id!)
                        .then(download => {
                            if (!download.isReady) {
                                return;
                            }

                            dispatch({ type: UsageActions.loadingCsv, payload: false });

                            clearInterval(timerId);

                            const endTime = new Date();
                            const elapsedSeconds = Math.abs(endTime.getTime() - startTime.getTime()) / 1000;
                            console.log(`Completed csv export in ${elapsedSeconds} seconds`);

                            const currentDate = GetFileNameFormattedDate(new Date());
                            const fileName = `Validation Tool CSV Data ${currentDate}.csv`;

                            DownloadUrl(download.downloadUrl!, fileName);
                        })
                }, 3000);
            }
        ).catch(er => {
            dispatch({ type: UsageActions.loadingCsv, payload: false });
            alert(GetErrorMessage(er,'Download CSV data'));
        });
    }

    function getSummary(): void {
        const messages: string[] = [];

        if (state.useStartDate && state.startDate == null) {
            messages.push('Start date is not set');
        }

        if (state.useEndDate && state.endDate == null) {
            messages.push('End date is not set');
        }

        if (messages.length > 0) {
            alert(`You must fix the following before you can filter:\n\n${messages.join('\n')}`);
            return;
        }

        const customerIds = state.customerIds.length > 0 ? state.customerIds : undefined;
        const jobIds = state.jobIds.length > 0 ? state.jobIds : undefined;
        const userIds = state.userIds.length > 0 ? state.userIds : undefined;
        const start = state.useStartDate ? state.startDate : undefined;
        const end = state.useEndDate ? state.endDate : undefined;

        dispatch({ type: UsageActions.loading, payload: true });
        service.GetUsageSummary(customerIds, userIds, jobIds, start ?? undefined, end ?? undefined)
            .then(
                data => {
                    dispatch({
                        type: UsageActions.multipleActions, payload: {
                            dataItems: data.CustomerData,
                            loading: false,
                            result: data,
                        }
                    });
                },
                er => {
                    alert(GetErrorMessage(er, 'Filter Data'));
                    dispatch({ type: UsageActions.loading, payload: false });
                });
    }

    function checkChanged(newState: boolean | 'indeterminate', isStart: boolean): void {
        const action = isStart ? UsageActions.useStartDate : UsageActions.useEndDate;
        dispatch({ type: action, payload: newState === true });
    }

    function onDateChange(newDate: Date | null | undefined, isStart: boolean): void {
        const action = isStart ? UsageActions.startDate : UsageActions.endDate;
        dispatch({ type: action, payload: newDate });
    }

    function onListChange(ids: string[], listType: string): void {
        let action: UsageActions;
        switch (listType) {
            case 'customer':
                action = UsageActions.customerIds;
                break;
            case 'user':
                action = UsageActions.userIds;
                break;
            case 'job':
                action = UsageActions.jobIds;
                break;
            default:
                return;
        }
        dispatch({ type: action, payload: ids })
    }

    return (
        <ContentWrapper>
            <FlexRow style={{ flex: 0 }}>
                <h1 style={Theme.typography.heading1}>Usage</h1>
            </FlexRow>
            <FlexColumn>
                <BlueButton style={{ marginBottom: '2em' }} onClick={downloadRaw}>
                    <FlexRowCentered>
                        {state.loadingCsv && <ProgressRing size={'small'} style={{ marginRight: '0.5em' }} />}
                        {!state.loadingCsv && <CloudDownArrowIcon style={{ marginRight: '0.5em' }} />}
                        <span style={Theme.typography.labelMedium}>Download Raw (csv) Data</span>
                    </FlexRowCentered>
                </BlueButton>
                <FlexRow style={{ flex: 0 }}>
                    <ColumnLeft style={Theme.typography.bodyMedium}>Customer Ids</ColumnLeft>
                    <ColumnRight style={Theme.typography.bodyMedium}>
                        <IdInput ids={state.customerIds}
                                 onChange={ids => onListChange(ids, 'customer')} />
                    </ColumnRight>
                </FlexRow>
                <FlexRow style={{ flex: 0 }}>
                    <ColumnLeft style={Theme.typography.bodyMedium}>User Ids</ColumnLeft>
                    <ColumnRight style={Theme.typography.bodyMedium}>
                        <IdInput ids={state.userIds} onChange={ids => onListChange(ids, 'user')} />
                    </ColumnRight>
                </FlexRow>
                <FlexRow style={{ flex: 0 }}>
                    <ColumnLeft style={Theme.typography.bodyMedium}>Job Ids</ColumnLeft>
                    <ColumnRight style={Theme.typography.bodyMedium}>
                        <IdInput ids={state.jobIds} onChange={ids => onListChange(ids, 'job')} />
                    </ColumnRight>
                </FlexRow>
                <FlexRow style={{ flex: 0 }}>
                    <ColumnLeft style={Theme.typography.bodyMedium}>
                        <FlexRowCentered>
                            <Checkbox
                                checked={state.useStartDate}
                                onChange={value => checkChanged(value, true)} />
                            <label style={{marginLeft: '0.5em'}}>Start Date</label>
                        </FlexRowCentered>
                    </ColumnLeft>
                    <ColumnRight style={Theme.typography.bodyMedium}>
                        <DatePicker
                            date={state.startDate}
                            disabled={!state.useStartDate}
                            onChange={value => onDateChange(value as Date, true)} />
                    </ColumnRight>
                </FlexRow>
                <FlexRow style={{ flex: 0 }}>
                    <ColumnLeft style={Theme.typography.bodyMedium}>
                        <FlexRowCentered>
                            <Checkbox
                                checked={state.useEndDate}
                                onChange={value => checkChanged(value, false)} />
                            <label style={{marginLeft: '0.5em'}}>End Date</label>
                        </FlexRowCentered>
                    </ColumnLeft>
                    <ColumnRight style={Theme.typography.bodyMedium}>
                        <DatePicker
                            date={state.endDate}
                            disabled={!state.useEndDate}
                            onChange={value => onDateChange(value as Date, false)} />
                    </ColumnRight>
                </FlexRow>
                <BlueButton style={{ marginBottom: '2em' }} onClick={getSummary}>
                    <FlexRowCentered>
                        <ArrowRotateTwoIcon style={{ marginRight: '0.5em' }} />
                        <span style={Theme.typography.labelMedium}>Refresh Data</span>
                    </FlexRowCentered>
                </BlueButton>
                <FlexFill style={{overflowY: 'auto'}}>
                    {
                        state.result && !state.loading &&
                        <>
                            <FlexRow style={{ flex: 0 }}>
                                <span style={Theme.typography.bodyMediumBold}>Overall Stats:</span>
                                <span style={{ width: '1em' }} />
                                <span
                                    style={Theme.typography.bodyMedium}>{state.result.TotalRunCount} total runs / {ConvertDuration.Convert(state.result.TotalRunTimeSeconds)}</span>
                            </FlexRow>
                            <UsageDataTree topItems={state.dataItems} />
                        </>
                    }
                    {
                        !state.loading && !state.result &&
                        <CenteringContainer style={{ flexDirection: 'column' }}>
                            <Illustration type={'pagesTextGrey'} height={200} width={200} />
                            <p style={Theme.typography.bodyLarge}>No Usage Data to Display</p>
                        </CenteringContainer>
                    }
                    {
                        state.loading &&
                        <CenteringContainer>
                            <ProgressRing size={'large'} />
                        </CenteringContainer>
                    }
                </FlexFill>
            </FlexColumn>
        </ContentWrapper>
    );
};

export default Usage;