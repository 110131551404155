import React, { useState } from 'react';
import Selector from "./Selector";
import { DefaultItem } from "@adsk/alloy-react-dropdown";
import { FilterItemData } from "../dataModel/FilterItemData";

const FilterItem = (
    {
        item,
        onSelectionChange
    }: {
        item: FilterItemData,
        onSelectionChange?: (selected: DefaultItem | null | undefined) => void
    }) => {
    const [internalSelected, setInternalSelected] = useState(item.selected);

    function filterChanged(selected: DefaultItem | null | undefined) {
        item.selected = selected;
        setInternalSelected(selected);
        if (onSelectionChange) {
            onSelectionChange(selected);
        }
    }

    return (
        <div>
            <p>{item.title}</p>
            <Selector
                selected={internalSelected}
                items={item.options}
                onSelectionChange={s => filterChanged(s)} />
        </div>
    );
};

export default FilterItem;