// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

import { FileUI } from './FileUI';
import { RecurrenceSettings } from './RecurrenceSettings';
import {
    JobStatusType
} from '../clients/Client';
import {
    ExportReportLocationType,
    FileDestinationNamingType
} from '../clients/V2Client';
import { DirectoryUI } from './DirectoryUI';
import { ProjectNameLoadBase } from './ProjectNameLoadBase';
import { TaskTrigger } from './Enums';

export class Task extends ProjectNameLoadBase {
    Id: string | undefined;
    UserId: string | undefined;
    CustomerId: string | undefined;
    Name: string | undefined;
    Models: FileUI[];
    Directories: DirectoryUI[];
    RawStatus: JobStatusType;

    IsPaused: boolean = false;
    Trigger: TaskTrigger | undefined;
    StartDate: Date;
    RecurrenceSettings: RecurrenceSettings | undefined;

    ExportExcel: boolean = false;
    ExportHtml: boolean = false;
    ExportLists: boolean = false;
    CombineExcel: boolean = false;

    ExportLocationType: ExportReportLocationType;
    ExportLocation: string | undefined;
    ExportProjectId: string | undefined;
    ExportDirectoryId: string | undefined;
    ExportDestinationNaming: FileDestinationNamingType;

    EmailOnCompletion: boolean = false;
    AttachExportFiles: boolean = false;

    LastRun: Date | undefined;
    NextRun: Date | undefined;

    LoadingPath = false;

    IsDuplicating = false;

    // noinspection JSUnusedGlobalSymbols - This is used by schedules and filtering
    get RunError(): boolean {
        return this.RawStatus === JobStatusType.Error;
    }

    // noinspection JSUnusedGlobalSymbols - This is used by schedules and filtering
    get PartialComplete(): boolean {
        return this.RawStatus === JobStatusType.PartiallyCompleted;
    }

    get ModelFilterField() {
        const modelNames: string[] = this.Models.map(m => m.Name);
        return modelNames.join('');
    }

    get DirectoryFilterField() {
        const directoryNames: string[] = this.Directories.map(m => m.Name);
        return directoryNames.join('');
    }

    get Status() {
        // const pipe = new TaskStatusPipe();
        // return pipe.transform(this.RawStatus);
        return 'error';
    }

    get IsRecurring() {
        return this.Trigger === 'Recurring';
    }

    get IsComplete() {
        if (this.RawStatus === JobStatusType.Error || this.RawStatus === JobStatusType.PartiallyCompleted) {
            switch (this.Trigger) {
                case 'OnceNow':
                case 'OnceLater':
                    return true;
                case 'Recurring':
                case 'OnPublish':
                    // Won't run again with recurring if it's in error
                    return false;
            }
        }
        if (this.Trigger === 'OnPublish') {
            return false;
        }
        return this.RawStatus === JobStatusType.Completed;
    }

    // noinspection JSUnusedGlobalSymbols - This is used by schedules and filtering
    get IsRunning() {
        return this.RawStatus === JobStatusType.Running || this.RawStatus === JobStatusType.PostProcessing;
    }

    // noinspection JSUnusedGlobalSymbols - This is used by schedules and filtering
    get IsScheduled() {
        return this.RawStatus === JobStatusType.Scheduled || this.Trigger === 'OnPublish';
    }

    constructor() {
        super();
        this.Models = [];
        this.Directories = [];
        this.RawStatus = JobStatusType.None;
        this.ExportDestinationNaming = FileDestinationNamingType.AppendTimestamp;
        this.ExportLocationType = ExportReportLocationType.ModelDirectory;
        this.Trigger = 'OnceNow';
        this.StartDate = new Date();
    }

    GetComparisonClone(): Task {
        const task = new Task();
        task.Id = this.Id;
        task.Name = this.Name;
        task.UserId = this.UserId;
        task.CustomerId = this.CustomerId;
        task.ExportHtml = this.ExportHtml;
        task.ExportExcel = this.ExportExcel;
        task.ExportLocationType = this.ExportLocationType;
        task.ExportLocation = this.ExportLocation;
        task.EmailOnCompletion = this.EmailOnCompletion;
        task.AttachExportFiles = this.AttachExportFiles;
        task.ExportDestinationNaming = this.ExportDestinationNaming;
        task.Trigger = this.Trigger;
        this.Directories.forEach(d => task.Directories.push(d));
        this.Models.forEach(f => task.Models.push(f));

        return task;
    }

    GetHubId(): string | undefined {
        if (this.Models != null && this.Models.length > 0) {
            return this.Models[0].HubId;
        }

        if (this.Directories != null && this.Directories.length > 0) {
            return this.Directories[0].HubId;
        }

        return undefined;
    }

    GetProjectId(): string | undefined {
        if (this.Models != null && this.Models.length > 0) {
            return this.Models[0].ProjectId;
        }

        if (this.Directories != null && this.Directories.length > 0) {
            return this.Directories[0].ProjectId;
        }

        return undefined;
    }
}
