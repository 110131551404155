import React from 'react';
import { Route } from "react-router-dom";
import { PAGES, PATHS } from "../../Constants";
import { ClientProvider } from "../../clients/ClientProvider";
import { Redirect } from 'react-router';

const authService = ClientProvider.AuthService;

const ProtectedRoute = ({ component, ...rest }: any) => {
    return (
        <Route {...rest}
               render={({ location }) => {
                   return authService.IsLoggedIn
                       ? component
                       : <Redirect to={{
                           pathname: PATHS[PAGES.DOOR],
                           state: { from: location }
                       }} />
               }} />
    );
};

export default ProtectedRoute;