import React, { useEffect, useReducer } from 'react';
import { CustomerService } from "../services/CustomerService";
import { reducer } from "../components/reducers/CustomersReducer";
import { CustomersState } from "../components/states/CustomersState";
import { CustomersActions } from "../Enums";
import {
    BlueButton, CenteringContainer,
    ColumnLeft,
    ColumnRight,
    ContentWrapper,
    FlexColumn,
    FlexRow,
    FlexRowCentered
} from '../CommonStyledComponents';
import {
    ActionButton,
    BasicButton, Illustration,
    Modal,
    PencilIcon, PlusIcon, ProgressRing,
    Table,
    Textarea,
    TextInput,
    TableBodyRow,
    TableBodyCell,
    Theme,
    Tooltip, CellProps, Checkbox
} from "@adsk/alloy-react";
import { CustomerUI } from "../dataModel/CustomerUI";
import {GetErrorMessage, ListToString, StringToList} from "../Utility";
import { EditCustomerRequestBase } from "../clients/Client";
import { CSSObject } from "styled-components";

const service = new CustomerService();

const Customers = () => {
    const [state, dispatch] = useReducer(reducer, new CustomersState());

    useEffect(() => {
        let isMounted = true;
        dispatch({ type: CustomersActions.loading, payload: true });
        service.GetAllCustomers()
            .then(customers => {
                if (!isMounted) {
                    return;
                }
                dispatch({ type: CustomersActions.multipleActions, payload: { customers: customers, loading: false } });
            });

        return () => {
            isMounted = false;
        }
    }, []);

    function startAddCustomer(): void {
        dispatch({
            type: CustomersActions.multipleActions, payload: {
                editingCustomer: new CustomerUI('', '', '', false),
                showEditDialog: true,
                editHeader: 'Add Customer',
            }
        });
    }

    function startEditCustomer(customer: CustomerUI): void {
        dispatch({
            type: CustomersActions.multipleActions, payload: {
                editingCustomer: { ...customer },
                showEditDialog: true,
                editHeader: 'Edit Customer',
            }
        });
    }

    function finishAddCustomer(customer: CustomerUI): void {
        dispatch({ type: CustomersActions.showEdit, payload: false });

        service.CreateCustomer(
            customer.Name,
            customer.EmailDomain,
            customer.AllowAllUsers,
            customer.ValidEmails)
            .then(
                c => {
                    if (c != null) {
                        state.customers.push(c);
                        dispatch({ type: CustomersActions.customers, payload: state.customers });
                    }
                })
            .catch(err => {
                alert(GetErrorMessage(err, 'Create customer'));
            });
    }

    function finishEditCustomer(customer: CustomerUI): void {
        dispatch({ type: CustomersActions.showEdit, payload: false });

        const existingCustomer = state.customers.find(c => c.ID === customer.ID);
        if (existingCustomer == null) {
            alert('Error: Existing Customer Not Found');
            return;
        }
        const dto = new EditCustomerRequestBase();
        if (existingCustomer.Name !== customer.Name.trim()) {
            dto.name = customer.Name.trim();
        }

        if (existingCustomer.AllowAllUsers !== customer.AllowAllUsers) {
            dto.allowAllUsers = customer.AllowAllUsers;
        }

        const addEmails: string[] = [];
        const removeEmails: string[] = [];

        for (const email of customer.ValidEmails) {
            if (email == null || email.trim() === '') {
                continue;
            }
            const found = existingCustomer.ValidEmails.find(e => e === email);
            if (found == null) {
                addEmails.push(email);
            }
        }

        for (const email of existingCustomer.ValidEmails) {
            const found = customer.ValidEmails.find(e => e === email);
            if (found == null) {
                removeEmails.push(email);
            }
        }

        if (addEmails.length > 0) {
            dto.addValidEmails = addEmails;
        }

        if (removeEmails.length > 0) {
            dto.removeValidEmails = removeEmails;
        }

        service.UpdateCustomer(customer.ID, dto)
            .then(
                () => {
                    existingCustomer.Name = customer.Name.trim();
                    existingCustomer.ValidEmails = customer.ValidEmails;
                    existingCustomer.AllowAllUsers = customer.AllowAllUsers;
                    dispatch({ type: CustomersActions.customers, payload: state.customers });
                })
            .catch(err => {
                alert(GetErrorMessage(err, 'Edit customer'));
            });
    }

    function finishDeleteCustomer(customer: CustomerUI): void {
        dispatch({ type: CustomersActions.showDelete, payload: false });

        service.DeleteCustomer(customer.ID)
            .then(
                () => {
                    const index = state.customers.indexOf(customer);
                    if (index === -1) {
                        return;
                    }

                    state.customers.splice(index, 1);
                    dispatch({ type: CustomersActions.customers, payload: state.customers });
                })
            .catch(err => {
                alert(GetErrorMessage(err,'Delete customer'));
            });
    }

    function handleAction(action: string, customer: CustomerUI): void {
        switch (action) {
            case 'delete':
                dispatch({
                    type: CustomersActions.multipleActions, payload: {
                        editingCustomer: customer,
                        showDeleteConfirm: true,
                    }
                });
                break;
        }
    }

    function customerEdited(f: () => void): void {
        f();
        dispatch({ type: CustomersActions.editingCustomer, payload: state.editingCustomer });
    }

    function renderActionCell(d: CellProps<CustomerUI>): JSX.Element {
        return (
            <FlexRow>
                <Tooltip content={'Edit this customer'}>
                    <BasicButton onClick={() => startEditCustomer(d.row.original)}>
                        <PencilIcon />
                    </BasicButton>
                </Tooltip>
                <ActionButton style={{ borderWidth: 0 }}
                              horizontal={false}
                              options={[
                                  { key: 'delete', label: 'Delete' },
                              ]} onChange={e => handleAction(e[0], d.row.original)} />
            </FlexRow>
        )
    }

    return (
        <ContentWrapper>
            <FlexRow>
                <h1 style={Theme.typography.heading1}>Customers</h1>
            </FlexRow>
            <BlueButton style={{ marginBottom: '2em' }} onClick={startAddCustomer}>
                <FlexRowCentered>
                    <PlusIcon style={{ marginRight: '0.5em' }} />
                    <span style={Theme.typography.labelMedium}>Add Customer</span>
                </FlexRowCentered>
            </BlueButton>
            {
                !state.loading &&
                // @ts-ignore
                <Table<CustomerUI> isSortable={true}
                                   renderBodyRow={({ style, row, ...props }) => {
                                       return <TableBodyRow<CustomerUI> {...props}
                                                                        row={row}
                                                                        style={{
                                                                            ...(style as CSSObject),
                                                                            height: 'inherit',
                                                                            minHeight: '48px'
                                                                        }}>
                                           {
                                               row.cells.map((cell) => {
                                                   const { style, ...cellProps } = cell.getCellProps({ style: { height: 'inherit' } });
                                                   return <TableBodyCell {...cellProps}
                                                                         style={{ ...style }}
                                                                         key={cellProps.key}
                                                                         cell={cell} />
                                               })
                                           }
                                       </TableBodyRow>;
                                   }}
                                   columns={[
                                       {
                                           accessor: 'Name',
                                           renderHeader: () => 'Name',
                                       },
                                       {
                                           accessor: 'EmailDomain',
                                           renderHeader: () => 'Domain',
                                       },
                                       {
                                           accessor: 'ValidEmails',
                                           renderHeader: () => 'Valid Emails',
                                           renderCell: d => {
                                               return d.row.original.AllowAllUsers ? '<Any>' : d.value?.join(', ') ?? '';
                                           }
                                       },
                                       {
                                           id: 'actions', width: 30,
                                           renderHeader: () => 'Actions',
                                           renderCell: d => renderActionCell(d)
                                       },
                                   ]} data={state.customers} />
            }
            {
                state.loading &&
                <CenteringContainer>
                    <ProgressRing size={'large'} />
                </CenteringContainer>
            }
            {
                !state.loading && state.customers.length === 0 &&
                <CenteringContainer style={{ flexDirection: 'column' }}>
                    <Illustration type={'pagesTextGrey'} height={200} width={200} />
                    <p style={Theme.typography.bodyLarge}>No Customers to Show</p>
                </CenteringContainer>
            }
            <Modal open={state.showEditDialog}>
                <Modal.Header>
                    {state.editHeader}
                </Modal.Header>
                <Modal.Body>
                    <FlexColumn style={{ height: '100%' }}>
                        <p style={Theme.typography.bodyMediumBold}>Edit the details of this customer.</p>
                        <FlexRowCentered>
                            <ColumnLeft>
                                <Tooltip content={'The display name of the customer'}>
                                    Organization
                                </Tooltip>
                            </ColumnLeft>
                            <ColumnRight>
                                <TextInput value={state.editingCustomer?.Name}
                                           onChange={e => customerEdited(() => state.editingCustomer!.Name = e.target.value)} />
                            </ColumnRight>
                        </FlexRowCentered>
                        <FlexRowCentered>
                            <ColumnLeft style={{ alignSelf: 'baseline' }}>
                                <Tooltip
                                    content={'The email domain used to identify customers (i.e. somewhere.com).  Note that this needs to match the email domain on their BIM 360 / Autodesk Construction Cloud account.'}>
                                    Domain
                                </Tooltip>
                            </ColumnLeft>
                            <ColumnRight>
                                <FlexColumn>
                                    <TextInput value={state.editingCustomer?.EmailDomain}
                                               disabled={state.editHeader.toLowerCase().includes('edit')}
                                               onChange={v => customerEdited(() => state.editingCustomer!.EmailDomain = v.target.value)} />
                                    <FlexRowCentered style={{ marginTop: '0.5em' }}>
                                        <Checkbox
                                            checked={state.editingCustomer?.AllowAllUsers}
                                            onChange={(e: any) => customerEdited(() => state.editingCustomer!.AllowAllUsers = e as boolean)} />
                                        <label style={{marginLeft: '0.5em'}}>Allow all users in this domain</label>
                                    </FlexRowCentered>
                                </FlexColumn>
                            </ColumnRight>
                        </FlexRowCentered>
                        <FlexRow>
                            <ColumnLeft style={{ alignSelf: 'auto' }}>
                                <Tooltip content={'The whitelisted emails for this domain.  Enter in comma delimited format'}>
                                    Valid Emails
                                </Tooltip>
                            </ColumnLeft>
                            <ColumnRight>
                                <Textarea
                                    defaultValue={state.editingCustomer == null ? '' : ListToString(state.editingCustomer.ValidEmails)}
                                    disabled={state.editingCustomer?.AllowAllUsers}
                                    onBlur={v => customerEdited(() => state.editingCustomer!.ValidEmails = StringToList(v.target.value))}
                                    style={{ width: '100%', minHeight: '100%' }} />
                            </ColumnRight>
                        </FlexRow>
                    </FlexColumn>
                </Modal.Body>
                <Modal.Footer>
                    <FlexRow style={{ justifyContent: 'end' }}>
                        <BlueButton
                            disabled={
                                state.editingCustomer?.EmailDomain == null
                                || state.editingCustomer?.EmailDomain === ''
                                || state.editingCustomer?.Name == null
                                || state.editingCustomer?.Name === ''
                                || (!state.editingCustomer?.AllowAllUsers && (state.editingCustomer?.ValidEmails == null || state.editingCustomer?.ValidEmails.length === 0))
                            }
                            onClick={() =>
                                state.editHeader.toLowerCase().includes('add')
                                    ? finishAddCustomer(state.editingCustomer!)
                                    : finishEditCustomer(state.editingCustomer!)
                            }>
                            <FlexRowCentered>
                                <span style={Theme.typography.labelMedium}>OK</span>
                            </FlexRowCentered>
                        </BlueButton>
                        <BlueButton style={{ marginLeft: '1em' }}
                                    onClick={() => dispatch({ type: CustomersActions.showEdit, payload: false })}>
                            <FlexRowCentered>
                                <span style={Theme.typography.labelMedium}>Cancel</span>
                            </FlexRowCentered>
                        </BlueButton>
                    </FlexRow>
                </Modal.Footer>
            </Modal>
            <Modal open={state.showDeleteConfirm}>
                <Modal.Header>Delete Customer?</Modal.Header>
                <Modal.Body>
                    <p style={Theme.typography.bodyMediumBold}>Are you sure you want to permanently delete the customer?</p>
                    <p style={Theme.typography.bodyMedium}>Customer will not have access to Validation Tool anymore without being
                        re-added to the system.</p>
                </Modal.Body>
                <Modal.Footer>
                    <FlexRow style={{ justifyContent: 'end' }}>
                        <BlueButton onClick={() => finishDeleteCustomer(state.editingCustomer!)}>
                            <FlexRowCentered>
                                <span style={Theme.typography.labelMedium}>Yes</span>
                            </FlexRowCentered>
                        </BlueButton>
                        <BlueButton style={{ marginLeft: '1em' }}
                                    onClick={() => dispatch({ type: CustomersActions.showDelete, payload: false })}>
                            <FlexRowCentered>
                                <span style={Theme.typography.labelMedium}>No</span>
                            </FlexRowCentered>
                        </BlueButton>
                    </FlexRow>
                </Modal.Footer>
            </Modal>
        </ContentWrapper>
    );
};

export default Customers;