import { TestReportSummaryDto } from "../../clients/V2Client";
import { ReportUI } from "../ReportUI";

export class ReportTranslator {
    static TranslateReport(apiReport: TestReportSummaryDto): ReportUI {
        return new ReportUI(
            apiReport.reportId!,
            apiReport.startTime!,
            apiReport.totalFiles ?? 0,
            apiReport.jobName!,
            apiReport.jobRunId ?? "",
            apiReport.jobRunId ?? "",
            apiReport.jobRunId ?? ""
        );
    }
}