import { Task } from "../../dataModel/Task";
import { EDIT_TAB_IDS, EDIT_TABS } from "../../Constants";
import { ProjectUI } from "../../dataModel/ProjectUI";
import { FileUI } from "../../dataModel/FileUI";

export class TaskEditState {
    Task: Task | undefined;
    OriginalTask: Task | undefined;
    Loading = true;
    IsNewTask = false;
    IsDuplicating = false;
    SelectedTab: string;
    Projects: ProjectUI[];
    LoadingProjects = false;
    Saving = false;
    showCancelConfirm = false;

    canSave = false;

    Recent: FileUI[];
    LoadingRecent = false;

    expandedSource: string[];
    expandedDestination: string[];

    selectedProjectSource: ProjectUI | undefined;
    selectedProjectDestination: ProjectUI | undefined;

    loadingExportPath: boolean = false;
    exportPath: string | undefined;

    constructor() {
        this.SelectedTab = EDIT_TAB_IDS[EDIT_TABS.NAME];
        this.Projects = [];
        this.expandedDestination = [];
        this.expandedSource = [];
        this.Recent = [];
    }
}