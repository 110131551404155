// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

import {RecurrenceSettings} from '../RecurrenceSettings';
import {Schedule} from '../../clients/Client';

export class CronTranslator {
  static GetCronString(settings: RecurrenceSettings): string {
    if (settings == null) {
      return '';
    }

    const minute: string = settings.StartDate.getUTCMinutes().toString();
    const hour: string = settings.StartDate.getUTCHours().toString();
    let day = '*';
    let month = '*';
    let dayOfWeek = '*';

    switch (settings.Recurrence) {
      case 'Daily':
        switch (settings.DayType) {
          case 'Numbered':
            day = `*/${settings.RepeatPeriod}`;
            break;
          case 'Weekday':
            dayOfWeek = '1-5';
            break;
        }
        break;
      case 'Weekly':
        const days: number[] = [];
        settings.WeekdaySettings.forEach(wd => {
          if (wd.Checked) {
            days.push(wd.Number);
          }
        });

        if (settings.RepeatPeriod === 1) {
          dayOfWeek = days.join(',');
        } else {
          dayOfWeek = days.join(',') + ' [ `expr \\`date +\\%s\\` / 86400 \\% ' + settings.RepeatPeriod + '` -eq 1 ]';
        }
        break;
      case 'Monthly':
        switch (settings.MonthlyRecurrence) {
          case 'NumberedDay':
            day = settings.DayOfMonth.toString();
            break;
          case 'Weekday':
            let weekDay: number;
            switch (settings.Day) {
              case 'Sunday':
                weekDay = 0;
                break;
              case 'Monday':
                weekDay = 1;
                break;
              case 'Tuesday':
                weekDay = 2;
                break;
              case 'Wednesday':
                weekDay = 3;
                break;
              case 'Thursday':
                weekDay = 4;
                break;
              case 'Friday':
                weekDay = 5;
                break;
              case 'Saturday':
                weekDay = 6;
                break;
            }
            switch (settings.WeekOfMonth) {
              case 'first':
                dayOfWeek = `${weekDay}#1`;
                break;
              case 'second':
                dayOfWeek = `${weekDay}#2`;
                break;
              case 'third':
                dayOfWeek = `${weekDay}#3`;
                break;
              case 'fourth':
                dayOfWeek = `${weekDay}#4`;
                break;
              case 'last':
                dayOfWeek = `${weekDay}L`;
                break;
            }
            break;
        }

        month = `*/${settings.RepeatPeriod}`;
        break;
    }

    return `${minute} ${hour} ${day} ${month} ${dayOfWeek}`;
  }

  static GetSettings(schedule: Schedule): RecurrenceSettings | undefined {
    if (schedule == null) {
      return undefined;
    }

    const settings = new RecurrenceSettings(schedule.startDate!, schedule.maxRuns!);
    settings.EndDate = schedule.endDate;

    if (schedule.endDate != null) {
      settings.EndType = 'Date';
    } else if (schedule.maxRuns! > 0) {
      settings.EndType = 'Occurrences';
    } else {
      settings.EndType = 'None';
    }

    const cron = schedule.cronString;
    if (cron != null && cron !== '') {
      const cronValues: string[] = cron.split(' ');

      const day = cronValues[2];
      const month = cronValues[3];
      const dayOfWeek = cronValues[4];

      let expression = null;
      if (cronValues.length > 12) {
        expression = cronValues[12];
      }

      if (day.includes('*/')) {
        settings.Recurrence = 'Daily';
        settings.DayType = 'Numbered';
        settings.RepeatPeriod = Number(day.replace('*/', ''));
      } else if (dayOfWeek === '1-5') {
        settings.Recurrence = 'Daily';
        settings.DayType = 'Weekday';
      } else if (month === '*') {
        settings.Recurrence = 'Weekly';
        const dayValues = dayOfWeek.split(',');

        settings.WeekdaySettings.forEach(wd => wd.Checked = dayValues.includes(wd.Number.toString()));
        if (expression != null) {
          settings.RepeatPeriod = Number(expression.charAt(0));
        } else {
          settings.RepeatPeriod = 1;
        }
      } else {
        settings.Recurrence = 'Monthly';

        if (month === '*') {
          settings.RepeatPeriod = 1;
        } else {
          settings.RepeatPeriod = Number(month.replace('*/', ''));
        }

        if (day === '*') {
          settings.MonthlyRecurrence = 'Weekday';
          const weekDay = Number(dayOfWeek.charAt(0));
          switch (weekDay) {
            case 0:
              settings.Day = 'Sunday';
              break;
            case 1:
              settings.Day = 'Monday';
              break;
            case 2:
              settings.Day = 'Tuesday';
              break;
            case 3:
              settings.Day = 'Wednesday';
              break;
            case 4:
              settings.Day = 'Thursday';
              break;
            case 5:
              settings.Day = 'Friday';
              break;
            case 6:
              settings.Day = 'Saturday';
              break;
          }

          if (dayOfWeek.includes('#')) {
            const dayRepeat = Number(dayOfWeek.charAt(2));
            switch (dayRepeat) {
              case 1:
                settings.WeekOfMonth = 'first';
                break;
              case 2:
                settings.WeekOfMonth = 'second';
                break;
              case 3:
                settings.WeekOfMonth = 'third';
                break;
              case 4:
                settings.WeekOfMonth = 'fourth';
                break;
            }
          } else {
            settings.WeekOfMonth = 'last';
          }
        } else {
          settings.MonthlyRecurrence = 'NumberedDay';
          settings.DayOfMonth = Number(day);
        }
      }
    }

    return settings;
  }
}
