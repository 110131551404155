import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {PAGES, PATHS} from '../Constants';
import {CenteringContainer} from '../CommonStyledComponents';
import {ClientProvider} from '../clients/ClientProvider';
import {ProgressRing} from '@adsk/alloy-react';
import {Link} from 'react-router-dom';

const authService = ClientProvider.AuthService;

const Landing = () => {
    const history = useHistory();
    const [primaryFeedback, setPrimaryFeedback] = useState<string | undefined>();
    const [SecondaryFeedback, setSecondaryFeedback] = useState<string | undefined>();
    const [loading, setLoading] = useState(false);
    const [showRetry, setShowRetry] = useState(false);

    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');

    useEffect(() => {
        let isMounted = true;

        if (code == null || code === '') {
            setPrimaryFeedback(`Sorry, we could not access Validation Tool`);
            setSecondaryFeedback(`No code found`);
            setShowRetry(true);
            return;
        }

        setLoading(true);
        authService.Login(code).then(result => {
            if (!isMounted) {
                return;
            }

            setLoading(false);
            if (result.Success) {
                const stateParam = queryParams.get('state');
                const passedState = stateParam == null ? undefined : JSON.parse(decodeURIComponent(stateParam));
                const redirectUrl = passedState?.returnPath ?? `${PATHS[PAGES.ROOT]}${PATHS[PAGES.OVERVIEW]}`;
                history.push(redirectUrl);
            } else {
                setPrimaryFeedback(`Sorry, we could not access Validation Tool`);
                setSecondaryFeedback(result.Message);
                setShowRetry(true);
            }
        });

        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <CenteringContainer style={{flexDirection: 'column'}}>
            {loading && <ProgressRing size={'xlarge'}/>}
            <div style={{fontSize: 'medium', fontWeight: 'bold'}}>{primaryFeedback}</div>
            <div style={{marginTop: '1em'}}>{SecondaryFeedback}</div>
            {showRetry && <Link to={PATHS[PAGES.DOOR]}>Try Again</Link>}
        </CenteringContainer>
    );
};

export default Landing;