import { CustomerUI } from "../../dataModel/CustomerUI";

export class CustomersState {
    public customers: CustomerUI[];
    public editingCustomer: CustomerUI | undefined;
    public showEditDialog = false;
    public showDeleteConfirm = false;
    public editHeader: string = 'Edit Customer';
    public loading: boolean = false;

    constructor() {
        this.customers = [];
    }
}