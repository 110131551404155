import React, { useState } from 'react';
import { TextInput } from "@adsk/alloy-react";
import { ListToString, StringToList } from "../Utility";

const IdInput = ({ ids, onChange }: { ids: string[], onChange: (newIds: string[]) => void }) => {
    const [idString, setIdString] = useState(ids.length === 0 ? undefined : ListToString(ids));

    function sendUpdate(): void {
        if (onChange) {
            onChange(idString == null ? [] : StringToList(idString));
        }
    }

    return (
        <TextInput placeholder={'Enter comma delimited ids...'}
                   value={idString}
                   onChange={v => setIdString(v.target.value)}
                   onBlur={sendUpdate} />
    );
};

export default IdInput;