import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import { CenteringContainer, FlexColumn } from "../CommonStyledComponents";
import { OverviewActions, ScrollSections } from "../Enums";
import {
    GetErrorMessage,
    PageElementIsCurrentScroll,
    ScrollIntoView
} from "../Utility";
import TaskList from "../components/TaskList";
import { ProgressRing } from "@adsk/alloy-react";
import { reducer } from "../components/reducers/OverviewReducer";
import { OverviewState } from "../components/states/OverviewState";
import { Task } from "../dataModel/Task";
import { TaskService } from "../services/TaskService";
import { ReportService } from "../services/ReportService";
import { ProjectService } from "../services/ProjectService";
import { ReportUI } from "../dataModel/ReportUI";

const taskService = new TaskService();
const reportService = new ReportService();
const projectService = new ProjectService();

let reportLoading = false;
let taskLoading = false;

const Overview = (
    {
        forceScrollSection,
        onScrollChanged
    }: {
        forceScrollSection: ScrollSections,
        onScrollChanged?: (section: ScrollSections) => void
    }) => {
    const [state, dispatch] = useReducer(reducer, new OverviewState());

    const parentDiv = useRef<HTMLDivElement>(null);

    const loadTasks = useCallback(() => {
        dispatch({ type: OverviewActions.taskLoading, payload: true });
        taskLoading = true;
        taskService.GetTasks()
            .then(tasks => {
                taskLoading = false;
                dispatch({
                    type: OverviewActions.multipleActions, payload: {
                        tasks: tasks,
                        taskLoading: false,
                        overallLoading: reportLoading
                    }
                });

                dispatch({ type: OverviewActions.projectsAndHubsLoading, payload: true });
                projectService.GetProjectAndHubNames(tasks).then(() => {
                    dispatch({ type: OverviewActions.projectsAndHubsLoading, payload: false });
                });
            })
            .catch(error => {
                alert(GetErrorMessage(error, 'Get tasks'));
                dispatch({ type: OverviewActions.taskLoading, payload: false });
            });
    }, []);

    function onTaskDelete(task: Task): void {
        const index = state.tasks.indexOf(task);
        if (index !== -1) {
            state.tasks.splice(index, 1);
            dispatch({ type: OverviewActions.tasks, payload: state.tasks });
        }
    }

    const loadReports = useCallback(() => {
        dispatch({ type: OverviewActions.reportLoading, payload: true });
        reportLoading = true;
        reportService.GetReports()
            .then(reports => {
                reportLoading = false;
                dispatch({
                    type: OverviewActions.multipleActions, payload: {
                        reports: reports,
                        reportLoading: false,
                        overallLoading: taskLoading
                    }
                });
            })
            .catch(error => {
                alert(GetErrorMessage(error, 'Get reports'));
                dispatch({ type: OverviewActions.reportLoading, payload: false });
            });
    }, []);

    useEffect(() => {
        if (parentDiv.current == null) {
            return;
        }
        dispatch({ type: OverviewActions.overallLoading, payload: true });
        loadTasks();
        loadReports();

        const handleScroll = (event: any) => {
            if (!onScrollChanged) {
                return;
            }

            let newSection = ScrollSections.tasks;
            if (PageElementIsCurrentScroll(ScrollSections.reports, event)) {
                newSection = ScrollSections.reports;
            }

            onScrollChanged(newSection);
        };

        // Note, adding and removing event listener is not strictly required but makes navigation cleaner UX.
        parentDiv.current.removeEventListener('scroll', handleScroll);
        switch (forceScrollSection) {
            case ScrollSections.tasks:
                ScrollIntoView(ScrollSections.tasks, parentDiv.current);
                break;
            case ScrollSections.reports:
                ScrollIntoView(ScrollSections.reports, parentDiv.current);
                break;
            default:
                ScrollIntoView(ScrollSections.tasks, parentDiv.current);
                break;
        }

        const currentParent = parentDiv.current;

        setTimeout(() => {
            currentParent?.addEventListener('scroll', handleScroll);
        }, 1000);

        return () => {
            currentParent?.removeEventListener('scroll', handleScroll);
        }
    }, [forceScrollSection, loadReports, loadTasks, onScrollChanged]);

    function onReportDelete(report: ReportUI): void {
        const newReports = [...state.reports];
        const found = newReports.find(r => r.Id === report.Id);
        if (found != null) {
            newReports.splice(newReports.indexOf(found), 1);
            dispatch({
                type: OverviewActions.reports,
                payload: newReports
            });
        }
    }

    return (
        <FlexColumn style={{ overflow: "auto" }} ref={parentDiv}>
            {
                state.overallLoading &&
                <CenteringContainer>
                    <ProgressRing size={'large'} />
                </CenteringContainer>
            }
            {
                !state.overallLoading &&
                <div>
                    <div id={ScrollSections.tasks}>
                        <TaskList tasks={state.tasks}
                                  loading={state.taskLoading}
                                  projectsAndHubsLoading={state.projectsAndHubsLoading}
                                  onReloadRequest={loadTasks}
                                  onDelete={onTaskDelete} />
                    </div>
                </div>
            }
        </FlexColumn>
    );
};

export default Overview;