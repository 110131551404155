import React, { useEffect, useState } from 'react';
import { SideNavigation } from '@adsk/alloy';
import { SideNavigationProvider } from '@adsk/alloy-side-navigation';
import { PAGE_TITLES, PAGES, PATHS } from "../Constants";
import { useHistory, useLocation } from "react-router";
import { ClientProvider } from "../clients/ClientProvider";
import { pages } from "../pages/Routes";
import { PageTypes, ScrollSections } from "../Enums";

const authService = ClientProvider.AuthService;

const Nav = (
    {
        scrollSection,
        onScrollChange
    }: {
        scrollSection: ScrollSections,
        onScrollChange?: (section: ScrollSections) => void
    }) => {
    const location = useLocation();
    const currentRoute = location.pathname.replace(PATHS[PAGES.ROOT], '');
    const currentPage = pages.find(p => currentRoute.startsWith(p.path));
    const [selected, setSelected] = useState(currentPage?.title ?? PAGE_TITLES[PAGES.OVERVIEW]);
    const history = useHistory();

    useEffect(() => {
        const currentRoute = location.pathname.replace(PATHS[PAGES.ROOT], '');
        if (!currentRoute.startsWith(PAGE_TITLES[PAGES.OVERVIEW])) {
            return;
        }

        switch (scrollSection) {
            case ScrollSections.tasks:
                setSelected('Tasks');
                break;
            case ScrollSections.reports:
                setSelected('Reports');
                break;
            default:
                setSelected('Tasks');
                break;
        }
    }, [location.pathname, scrollSection])

    const pageItems = pages
        .filter(p => p.uiVisible && (p.type !== PageTypes.admin || authService.CurrentUser?.isAdmin === true))
        .map(p => {
            return { key: p.title, name: p.title, onClick: () => itemClicked(p), renderIcon: p.renderIcon };
        });

    function itemClicked(page: { title: string, path: string, scrollLocation: ScrollSections | undefined }): void {
        if (selected === page.title) {
            return;
        }
        setSelected(page.title);

        if (page.scrollLocation != null) {
            if (currentPage?.title !== PAGE_TITLES[PAGES.OVERVIEW]) {
                history.push(`${PATHS[PAGES.ROOT]}${[PATHS[PAGES.OVERVIEW]]}`)
            }
            if (onScrollChange) {
                onScrollChange(page.scrollLocation);
            }
        } else {
            history.push(`${PATHS[PAGES.ROOT]}${page.path}`)
        }
    }

    return (
        <SideNavigationProvider>
            <SideNavigation
                selectedId={selected}
                style={{ height: '100%' }}
                topItems={pageItems} />
        </SideNavigationProvider>
    );
};

export default Nav;