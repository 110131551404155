import { IClient } from "../clients/Client";
import { ProjectUI } from "../dataModel/ProjectUI";
import { ProjectTranslator } from "../dataModel/translators/ProjectTranslator";
import { ProjectNameLoadBase } from "../dataModel/ProjectNameLoadBase";
import { GetErrorMessage } from "../Utility";
import { ReportUI } from "../dataModel/ReportUI";
import { ClientProvider } from "../clients/ClientProvider";

export class ProjectService {
    private _client: IClient = ClientProvider.Client;
    private cachedProjects: ProjectUI[] = [];

    GetProjects(): Promise<ProjectUI[]> {
        return this._client.listAll(true)
            .then(projects => projects.map(project => ProjectTranslator.TranslateProject(project)));
    }

    GetProject(hubId: string, id: string): Promise<ProjectUI> {
        return this._client.getProject(hubId, id)
            .then(project => ProjectTranslator.TranslateProject(project));
    }

    GetProjectAndHubNames(baseObjects: ProjectNameLoadBase[]): Promise<boolean> {
        const projectIds: { hubId: string, projectId: string }[] = [];
        let needsFullLoad = false;
        baseObjects.forEach(baseObject => {
            baseObject.LoadingProjectName = true;
            const hubId = baseObject.GetHubId();
            const projectId = baseObject.GetProjectId();

            if (hubId == null || projectId == null) {
                needsFullLoad = true;
                return;
            }

            const existing = projectIds.find(p => p.hubId === hubId && p.projectId === projectId);
            if (existing == null) {
                projectIds.push({ hubId: hubId, projectId: projectId });
            }
        });

        if (needsFullLoad) {
            return this.PopulateCacheFull()
                .then(() => {
                    baseObjects.forEach(b => {
                        const project = this.FindProjectInCache(b.GetProjectId()!);
                        if (project == null) {
                            b.LoadErrorDetail = `Could not get hub and project name, project was not found.`;
                            b.HasLoadError = true;
                            b.HubName = '-Unknown-';
                            b.ProjectName = '-Unknown-';
                            b.LoadingProjectName = false;
                        } else {
                            b.LoadingProjectName = false;
                            b.HubName = project.HubName;
                            b.ProjectName = project.Name;
                        }
                    });

                    return true;
                });
        } else {
            const promises = projectIds.map(ids => {
                return this.PopulateCacheIndividual(ids.hubId, ids.projectId)
                    .then(() => {
                            const project = this.FindProjectInCache(ids.projectId);
                            baseObjects.forEach(b => {
                                if (b.GetProjectId() !== project?.Id) {
                                    return;
                                }
                                b.LoadingProjectName = false;
                                b.HubName = project!.HubName;
                                b.ProjectName = project!.Name;
                            });

                            return true;
                        },
                        err => {
                            console.error(err);
                            baseObjects.forEach(b => {
                                if (b.GetProjectId() !== ids.projectId) {
                                    return;
                                }
                                b.LoadingProjectName = false;
                                b.LoadErrorDetail = GetErrorMessage(err, 'Get Hub and Project Name');
                                b.HasLoadError = true;
                                b.HubName = '-Unknown-';
                                b.ProjectName = '-Unknown-';
                            });

                            return true;
                        });
            });

            return Promise.all(promises).then(results => !results.includes(false));
        }
    }

    CheckModelPaths(report: ReportUI): Promise<ProjectUI | undefined> {
        // Make sure we have models
        if (report.Models.length <= 0) {
            return Promise.resolve(undefined);
        }

        // If we have it cached use that
        const project = this.cachedProjects.find(p => p.Id === report.Models[0].ProjectId);
        if (project != null) {
            return Promise.resolve(undefined);
        }

        // Not cached, try loading an individual project
        const hubId = report.Models[0].HubId;
        const projectId = report.Models[0].ProjectId;

        if (!(hubId == null || projectId == null)) {
            return this.PopulateCacheIndividual(hubId, projectId)
                .then(() => this.FindProjectInCache(projectId));
        }

        // Something was not set, try loading all projects and finding it in the list
        return this.PopulateCacheFull()
            .then(() => {
                const loadedProject = this.FindProjectInCache(projectId);

                // Populate if it was in the loaded set or unknown if not.
                if (loadedProject == null) {
                    return undefined;
                } else {
                    return loadedProject;
                }
            });
    }

    private PopulateCacheFull(): Promise<boolean> {
        return this.GetProjects()
            .then(projects => {
                projects.forEach(p => {
                    const existing = this.FindProjectInCache(p.Id);
                    if (existing != null) {
                        return;
                    }
                    this.cachedProjects.push(p);
                });
                return true;
            });
    }

    private PopulateCacheIndividual(hubId: string, projectId: string): Promise<boolean> {
        const existing = this.FindProjectInCache(projectId);
        if (existing != null) {
            return Promise.resolve(true);
        }
        return this.GetProject(hubId, projectId)
            .then(p => {
                    this.cachedProjects.push(p);
                    return true;
                }
            );
    }

    private FindProjectInCache(id: string): ProjectUI | undefined {
        return this.cachedProjects.find(p => p.Id === id);
    }
}