import { Constants } from "../Constants";

export class ConvertTextTruncation {
    public static Convert(value: any): any {
        if (value == null) {
            return null;
        }

        if (typeof value === 'string') {
            return value.length <= Constants.MaxJobUrlCharacters ? value : value.substring(0, Constants.MaxJobUrlCharacters) + '...';
        }

        return value;
    }
}