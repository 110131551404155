import { FlexRow } from "./CommonStyledComponents";
import { Constants } from "./Constants";
import { CellProps, InfoPopover, OverflowTooltip, ProgressRing } from "@adsk/alloy-react";
import React from "react";
import { FileUI } from "./dataModel/FileUI";

export function GetListCell(values: any[]): JSX.Element {
    const finalValues = values.filter(v => v.Name != null && v.Name.toLowerCase() !== '');
    return (
        <OverflowTooltip>
            {finalValues.length === 0 && Constants.NoneString}
            {finalValues.length === 1 && finalValues[0].Name}
            {finalValues.length > 1 &&
                <FlexRow>
                    <OverflowTooltip
                        style={{
                            fontStyle: 'italic',
                            flex: '1'
                        }}>{Constants.MultipleSelectionString}</OverflowTooltip>
                    <InfoPopover message={finalValues.map(f => f.Name).join(', ')} />
                </FlexRow>
            }
        </OverflowTooltip>
    )
}

export function getLoadingCell(d: CellProps<FileUI, any>): JSX.Element {
    return (
        <>
            {
                !d.row.original.LoadingProjectName &&
                <OverflowTooltip>{d.value}</OverflowTooltip>
            }
            {
                d.row.original.LoadingProjectName &&
                <ProgressRing size={'xsmall'} />
            }
        </>
    )
}

export function GetResultClass(passPercent: number): string {
    return passPercent < 70
        ? 'failResult'
        : passPercent < 90
            ? 'warnResult'
            : 'passResult';
}