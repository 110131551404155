import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';

import { stylePropType } from '@adsk/alloy-react-helpers';
import ProgressRing from '@adsk/alloy-react-progress-ring';
import { TreeNode } from "@adsk/alloy-react";

const ProgressNode = forwardRef<HTMLLIElement, any>(
    ({ depth = 0, style, ...props }, ref) => {
        if (depth <= 0) return null;
        return (
            <TreeNode
                id=""
                ref={ref}
                label=""
                depth={depth}
                style={{ ...(style as object) }}
                {...props}
            >
                <ProgressRing size="small" />
            </TreeNode>
        );
    }
);
ProgressNode.displayName = 'ProgressNode';

ProgressNode.propTypes = {
    /* Determines whether to display the current node */
    show: PropTypes.bool.isRequired,
    /* style object to add to the wrapper of the node */
    depth: PropTypes.number,
    /* style object to add to the wrapper of the node */
    style: stylePropType,
};

export default ProgressNode;