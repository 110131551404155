import { Task } from "../../dataModel/Task";
import { ReportUI } from "../../dataModel/ReportUI";

export class OverviewState {
    taskLoading = false;
    reportLoading = false;
    overallLoading = false;
    projectsAndHubsLoading = false;

    tasks: Task[];
    reports: ReportUI[];

    constructor() {
        this.tasks = [];
        this.reports = [];
    }
}