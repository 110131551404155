import { OverviewState } from "../states/OverviewState";
import { OverviewActions } from "../../Enums";

export function reducer(state: OverviewState, action: { type: OverviewActions, payload: any }): OverviewState {
    switch (action.type) {
        case OverviewActions.overallLoading:
            return { ...state, overallLoading: action.payload };
        case OverviewActions.projectsAndHubsLoading:
            return { ...state, projectsAndHubsLoading: action.payload };
        case OverviewActions.multipleActions:
            return { ...state, ...action.payload };
        case OverviewActions.taskLoading:
            return { ...state, taskLoading: action.payload };
        case OverviewActions.reportLoading:
            return { ...state, reportLoading: action.payload };
        case OverviewActions.tasks:
            return { ...state, tasks: action.payload };
        case OverviewActions.reports:
            return { ...state, reports: action.payload };
    }
}