import React from 'react';
import { Control, DefaultItem, SingleSelectMenu, useDropdown } from "@adsk/alloy-react-dropdown";

interface SelectorArgs {
    items: DefaultItem[];
    selected?: DefaultItem | null;
    onSelectionChange?: (selected: DefaultItem | null | undefined) => void;

    [k: string]: any;
}

const Selector = ({ items, selected, onSelectionChange, disabled, ...args }: SelectorArgs) => {
    const {
        isOpen,
        menuItems,
        getToggleButtonProps,
        getLabelProps,
        getMenuProps,
        getItemProps,
        selectedItem,
        highlightedIndex,
        targetProps,
    } = useDropdown({
        items,
        selectedItem: selected,
        onSelectedItemChange: c => {
            if (onSelectionChange) {
                onSelectionChange(c.selectedItem);
            }
        },
        ...args,
    });

    return (
        <div style={{ width: 300 }}>
            <Control
                isOpen={isOpen} disabled={disabled}
                selectedItem={selectedItem}
                getToggleButtonProps={getToggleButtonProps}
                getLabelProps={getLabelProps}
                targetProps={targetProps}
            />
            <SingleSelectMenu
                isOpen={isOpen}
                menuItems={menuItems}
                targetProps={targetProps}
                highlightedIndex={highlightedIndex}
                selectedItem={selectedItem}
                getMenuProps={getMenuProps}
                getItemProps={getItemProps}
            />
        </div>
    );
};

export default Selector;