// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

import {
    Directory360,
    DirectoryDto,
    File360,
    FileDto,
    JobDirectory,
    JobFile,
    HubType, ProjectType
} from '../../clients/Client';
import { DirectoryUI } from '../DirectoryUI';
import { FileUI } from '../FileUI';

export class FileStructureTranslator {
    static GetDirectory(apiDirectory: Directory360 | DirectoryDto | JobDirectory, isProjectFiles: boolean): DirectoryUI {
        const directory = new DirectoryUI(apiDirectory.id!, '', apiDirectory.projectId!);

        if (apiDirectory instanceof DirectoryDto || apiDirectory instanceof JobDirectory) {
            directory.HubId = apiDirectory.hubId;
        }

        if (apiDirectory instanceof Directory360 || apiDirectory instanceof JobDirectory) {
            directory.ParentId = apiDirectory.parentId;
            directory.Name = apiDirectory.name!;
            directory.LastChange = apiDirectory.lastUpdated;
        }

        if (apiDirectory instanceof JobDirectory) {
            directory.HubRegion = apiDirectory.region;
            directory.HubType = apiDirectory.hubType ?? HubType.Unknown;
            directory.ProjectType = apiDirectory.projectType ?? ProjectType.Unknown;
        }

        if (isProjectFiles) {
            directory.Icon = 'assets/images/Icon_ProjectFiles.png';
        }

        return directory;
    }

    static GetApiDirectoryDto(directory: DirectoryUI): DirectoryDto {
        return new DirectoryDto({
            projectId: directory.ProjectId,
            id: directory.Id,
            hubId: directory.HubId,
            recursive: directory.Recursive
        });
    }

    static GetFile(apiFile: File360 | JobFile): FileUI {
        if (apiFile.isDirectory) {
            throw Error('Tried to convert directory in file translator');
        }
        const fileName = apiFile.fileNameWithExtension
            ?? `${apiFile.name!}${apiFile.fileType == null
                ? ''
                : `.${apiFile.fileType?.fileExtension}`}`;
        const file = new FileUI(apiFile.id!, fileName, apiFile.projectId!, apiFile.directoryId!, apiFile.fileType!);
        file.ParentId = apiFile.parentId;
        file.FileSize = apiFile.size;
        file.LastChange = apiFile.lastUpdated;
        if (!apiFile.isDirectory) {
            file.Icon = apiFile.thumbnail ?? 'assets/images/Default_File.png';
            file.LastUpdate = apiFile.lastUpdated!;
            file.UpdateBy = apiFile.lastUpdatedBy!;
            file.Version = apiFile.versionNumber?.toString() ?? '';
        }

        if (apiFile instanceof JobFile) {
            file.HubId = apiFile.hubId;
            file.HubName = apiFile.hubName;
            file.HubRegion = apiFile.region;
            file.HubType = apiFile.hubType ?? HubType.Unknown;
            file.ProjectType = apiFile.projectType ?? ProjectType.Unknown;
        }

        return file;
    }

    static GetApiFileDto(file: FileUI): FileDto {
        return new FileDto({
            id: file.Id,
            projectId: file.ProjectId,
            hubId: file.HubId
        });
    }
}
