import React from 'react';
import {
    Checkbox, DocumentPlusCircleIcon,
    FolderArrowRightIcon,
    FolderIcon, FolderPlusIcon, IconButton, Theme, Tooltip
} from "@adsk/alloy-react";
import { FileUI } from "../dataModel/FileUI";
import { DirectoryUI } from "../dataModel/DirectoryUI";
import { ConvertFileIcon } from "../converters/ConvertFileIcon";
import { BIM360ItemBase } from "../dataModel/BIM360ItemBase";
import { FlexRow, FlexRowCentered } from "../CommonStyledComponents";
import { ConvertFileSize } from "../converters/ConvertFileSize";
import { ConvertRunDate } from "../converters/ConvertRunDate";

const TreeNodeFileContent = (
    {
        item,
        treeNodeProps,
        allowSelection,
        allowMultiSelection,
        allowAdd,
        onAdd
    }: {
        item: BIM360ItemBase,
        treeNodeProps: any,
        allowSelection: boolean,
        allowMultiSelection: boolean,
        allowAdd: boolean,
        onAdd?: (item: BIM360ItemBase, recursive: boolean) => void,
    }) => {
    function add(e: React.MouseEvent, recursive: boolean): void {
        e.stopPropagation();
        if (onAdd) {
            onAdd(item, recursive);
        }
    }

    return (
        <FlexRowCentered>
            {
                allowSelection && allowMultiSelection && item instanceof FileUI &&
                <Checkbox style={{ marginRight: '0.5em' }} />
            }
            {
                allowSelection && allowMultiSelection && item instanceof DirectoryUI &&
                <FolderIcon style={{ marginRight: '1em' }} />
            }
            {
                allowAdd && item instanceof DirectoryUI &&
                <>
                    <Tooltip content={'Add this folder only'}>
                        <IconButton onClick={(e) => add(e, false)}><FolderPlusIcon /></IconButton>
                    </Tooltip>
                    <Tooltip content={'Add this folder recursively'}>
                        <IconButton style={{ marginRight: '1em' }} onClick={(e) => add(e, true)}>
                            <FolderArrowRightIcon />
                        </IconButton>
                    </Tooltip>
                </>
            }
            {
                allowAdd && item instanceof FileUI &&
                <Tooltip content={'Add this model'}>
                    <IconButton style={{ marginRight: '1em' }} onClick={(e) => add(e, false)}>
                        <DocumentPlusCircleIcon />
                    </IconButton>
                </Tooltip>
            }
            {item instanceof DirectoryUI && !allowMultiSelection && <FolderIcon style={{ marginRight: '0.5em' }} />}
            {
                !(item instanceof DirectoryUI) &&
                <div style={{
                    marginRight: '0.5em',
                    width: '24px',
                    height: '24px',
                    padding: '4px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <img
                        src={ConvertFileIcon.Convert(item as BIM360ItemBase)}
                        alt={'File'}
                        style={{ width: '20px', height: '20px', objectFit: 'contain' }} />
                </div>
            }
            <FlexRow style={{ alignItems: 'baseline' }}>
                {
                    <span>{treeNodeProps.label}</span>
                }
                {
                    item instanceof FileUI &&
                    <FlexRowCentered style={{ marginLeft: '1em' }}>
                      <span style={Theme.typography.labelSmall}>
                        {ConvertFileSize.Convert(item.FileSize!)} - Updated {ConvertRunDate.Convert(item.LastChange)}
                      </span>
                    </FlexRowCentered>}
            </FlexRow>
        </FlexRowCentered>
    );
};

export default TreeNodeFileContent;