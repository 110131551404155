import { CreateCustomerRequestBase, EditCustomerRequestBase, IClient } from "../clients/Client";
import { CustomerUI } from "../dataModel/CustomerUI";
import { CustomerTranslator } from "../dataModel/translators/CustomerTranslator";
import { ClientProvider } from "../clients/ClientProvider";

export class CustomerService {
    private _client: IClient = ClientProvider.Client;

    GetAllCustomers(): Promise<CustomerUI[]> {
        return this._client.listCustomers()
            .then(customers => customers.map(customer => CustomerTranslator.TranslateCustomer(customer)));
    }

    GetCustomer(id: string): Promise<CustomerUI> {
        return this._client.getCustomer(id)
            .then(c => CustomerTranslator.TranslateCustomer(c));
    }

    CreateCustomer(name: string, domain: string, allowAllUsers: boolean, validEmails: string[] | undefined): Promise<CustomerUI> {
        const create = new CreateCustomerRequestBase();
        create.name = name;
        create.emailDomain = domain;
        create.allowAllUsers = allowAllUsers;
        if (validEmails != null) {
            create.validEmails = validEmails;
        }

        return this._client.createCustomer(create)
            .then(customer => CustomerTranslator.TranslateCustomer(customer));
    }

    DeleteCustomer(domain: string): Promise<void> {
        return this._client.deleteCustomer(domain);
    }

    UpdateCustomer(domain: string, dto: EditCustomerRequestBase): Promise<void> {
        return this._client.updateCustomer(domain, dto);
    }
}