import {
    CreateJobRequestBase,
    EditJobRequestBase,
    EditScheduleRequest,
    IClient, JobRunBase,
    JobScheduleType
} from "../clients/Client";
import { Task } from "../dataModel/Task";
import { TaskTranslator } from "../dataModel/translators/TaskTranslator";
import { CronTranslator } from "../dataModel/translators/CronTranslator";
import { ClientProvider } from "../clients/ClientProvider";

export class TaskService {
    private _client: IClient = ClientProvider.Client;

    GetTasks(): Promise<Task[]> {
        return this._client.getJobs()
            .then(jobs => jobs.map(job => TaskTranslator.GetTask(job)));
    }

    CreateTask(command: CreateJobRequestBase): Promise<Task> {
        return this._client.createJob(command)
            .then(job => TaskTranslator.GetTask(job));
    }

    GetTask(id: string): Promise<Task> {
        return this._client.getJob(id)
            .then(job => TaskTranslator.GetTask(job));
    }

    RunTask(id: string): Promise<JobRunBase> {
        return this._client.startJob(id);
    }

    GetTaskUpdate(original: Task, updated: Task): EditJobRequestBase {
        const scheduleNew = TaskTranslator.GetScheduleFromTask(updated);

        let scheduleEdit: EditScheduleRequest | undefined = undefined;

        let scheduleType: JobScheduleType = JobScheduleType.None;

        switch (updated.Trigger) {
            case 'OnceNow':
                scheduleType = JobScheduleType.OnceNow;
                break;
            case 'OnceLater':
                scheduleType = JobScheduleType.OnceLater;
                break;
            case 'Recurring':
                scheduleType = JobScheduleType.Recurring;
                break;
            case 'OnPublish':
                scheduleType = JobScheduleType.OnPublish;
                break;
        }

        if (scheduleNew != null) {
            scheduleEdit = new EditScheduleRequest({
                cronString: CronTranslator.GetCronString(updated.RecurrenceSettings!),
                startDate: scheduleNew.startDate,
                endDate: scheduleNew.endDate,
                clearEndDate: false,
                clearStartDate: false,
                maxRuns: scheduleNew.maxRuns
            });
        }

        return new EditJobRequestBase({
            name: original.Name === updated.Name ? undefined : updated.Name,
            jobScheduleType: scheduleType,
            editSchedule: scheduleEdit,
            runOnceDateTime: scheduleType === JobScheduleType.OnceLater ? updated.StartDate! : undefined
        });
    }

    UpdateTask(task: Task, update: EditJobRequestBase): Promise<Task> {
        return this._client.editJob(task.Id!, update)
            .then(job => TaskTranslator.GetTask(job));
    }

    DeleteTask(task: Task): Promise<boolean> {
        return this._client.deleteJob(task.Id!).then(() => true);
    }

    ToggleTaskPause(task: Task): Promise<boolean> {
        const command: EditJobRequestBase = new EditJobRequestBase({
            pauseJob: !task.IsPaused
        });

        return this._client.editJob(task.Id!, command)
            .then(_job => {
                task.IsPaused = _job.schedule != null && _job.schedule.isPaused!;
                task.RawStatus = _job.status!;
                return true;
            });
    }
}