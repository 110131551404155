import { TasksActions } from "../../Enums";
import { TasksState } from "../states/TasksState";

export function reducer(state: TasksState, action: { type: TasksActions, payload: any }): TasksState {
    switch (action.type) {
        case TasksActions.filteredTasks:
            return { ...state, filteredTasks: action.payload };
        case TasksActions.multipleActions:
            return { ...state, ...action.payload };
        case TasksActions.filterOpen:
            return { ...state, filterOpen: action.payload };
    }
}