import React from 'react';
import { FlexRowCentered, FlexFill } from '../CommonStyledComponents';
import styled from "styled-components";
import { Divider, Theme } from "@adsk/alloy-react";
import Profile from "./Profile";
import InfoMenu from "./InfoMenu";
import logo from '../assets/Logo_AVT.svg';

const FlexDivider = styled(Divider)`
  align-self: stretch;
  margin-left: 0.5em;
  margin-right: 0.5em;
  min-height: 100%;
  height: auto;
`;

const Wrapper = styled(FlexRowCentered)`
  padding: 0.5em;
  border-top: 18px solid black;
`;

const EnvironmentTag = styled.div`
  border: 2px solid ${Theme.colors.adskBlue500};
  border-radius: 6px;
  padding: 0.25em 1em;
  margin: 0 2em;
  background: wheat;
  color: ${Theme.colors.adskBlue500};
  font-weight: bold;
`;

const HeaderBar = () => {
    return (
        <Wrapper>
            <img src={logo} style={{ height: '32px' }} alt={'Autodesk Validation Tool Logo'} />
            {
                process.env.REACT_APP_HEADER_TAG != null && process.env.REACT_APP_HEADER_TAG !== '' && (
                    <EnvironmentTag>{process.env.REACT_APP_HEADER_TAG}</EnvironmentTag>
                )
            }
            <FlexFill />
            <InfoMenu />
            <FlexDivider variant='vertical' />
            <Profile />
        </Wrapper>
    );
};

export default HeaderBar;