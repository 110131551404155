export class ConvertDuration {
    public static Convert(value: number): string {
        // Assume value is in seconds
        let minutes = Math.floor(value / 60);
        let seconds = Math.round(value - (minutes * 60));

        if (seconds === 60) {
            minutes++;
            seconds = 0;
        }

        if (minutes < 60) {
            return `${minutes}m ${seconds}s`;
        } else {
            const hours = Math.floor(minutes / 60);
            minutes = Math.floor(minutes - (hours * 60));
            return `${hours}h ${minutes}m ${seconds}s`;
        }
    }
}