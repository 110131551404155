import { JobsActions } from "../../Enums";
import { JobsState } from "../states/JobsState";

export function reducer(state: JobsState, action: { type: JobsActions, payload: any }): JobsState {
    switch (action.type) {
        case JobsActions.showDetail:
            return { ...state, showDetail: action.payload };
        case JobsActions.detailObject:
            return { ...state, detailObject: action.payload };
        case JobsActions.hasMoreData:
            return { ...state, hasMoreData: action.payload };
        case JobsActions.loadingMoreData:
            return { ...state, loadingMoreData: action.payload };
        case JobsActions.loading:
            return { ...state, loading: action.payload };
        case JobsActions.multipleActions:
            return { ...state, ...action.payload };
        case JobsActions.dataItems:
            return { ...state, dataItems: action.payload };
        case JobsActions.customerIds:
            return { ...state, customerIds: action.payload };
        case JobsActions.userIds:
            return { ...state, userIds: action.payload };
        case JobsActions.jobIds:
            return { ...state, jobIds: action.payload };
        case JobsActions.startDate:
            return { ...state, startDate: action.payload };
        case JobsActions.useStartDate:
            return { ...state, useStartDate: action.payload };
        case JobsActions.endDate:
            return { ...state, endDate: action.payload };
        case JobsActions.useEndDate:
            return { ...state, useEndDate: action.payload };
        case JobsActions.sortingField:
            return { ...state, sortingField: action.payload };
        case JobsActions.sortAscending:
            return { ...state, sortAscending: action.payload };
    }
}