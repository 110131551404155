import React, { useState } from 'react';
import { TextInput, Theme } from "@adsk/alloy-react";
import { Task } from "../dataModel/Task";

const SettingsName = ({ task, onChange }: { task: Task | undefined, onChange?: (value: string) => void }) => {
    const [internalValue, setInternalValue] = useState(task?.Name ?? '');

    function sendUpdate(): void {
        if (task == null) {
            return;
        }

        task.Name = internalValue;

        if (onChange) {
            onChange(internalValue);
        }
    }

    return (
        <div>
            <h2 style={Theme.typography.heading2}>Name</h2>
            <TextInput value={internalValue}
                       placeholder={'Enter a task name...'}
                       onKeyDown={sendUpdate}
                       onChange={v => setInternalValue(v.target.value)}
                       onBlur={sendUpdate} />
        </div>
    );
};

export default SettingsName;