import React from 'react';
import { Route } from "react-router-dom";
import { Redirect } from "react-router";
import { PAGES, PATHS } from "../../Constants";
import { ClientProvider } from "../../clients/ClientProvider";

const authService = ClientProvider.AuthService;

const AdminRoute = ({ component, ...rest }: any) => {
    return (
        <Route {...rest}
               render={({ location }) => {
                   return authService.IsLoggedIn && authService.CurrentUser?.isAdmin === true
                       ? component
                       : <Redirect to={{
                           pathname: PATHS[PAGES.DOOR],
                           state: { from: authService.IsLoggedIn ? undefined : location }
                       }} />
               }} />
    );
};

export default AdminRoute;