import { RecurrenceSettings } from "../dataModel/RecurrenceSettings";
import { IntervalUnit, RunOn, ScheduleData } from "@adsk/alloy-react-schedule/es/types";
import { ConvertRunDate } from "./ConvertRunDate";

export class ConvertAutodeskSchedule {
    public static GetAutodeskSchedule(uiSettings: RecurrenceSettings): ScheduleData | undefined {
        if (uiSettings.Recurrence == null) {
            return undefined;
        }

        let startDate = uiSettings.StartDate;
        let endDate = uiSettings.EndDate ?? new Date(2030, 1, 1);
        let runOn: RunOn | RunOn[] = null;
        let runAtTime: string = this.GetRunTime(uiSettings.StartDate);
        let unit: IntervalUnit;
        let unitCycle = uiSettings.RepeatPeriod;

        switch (uiSettings.Recurrence) {
            case 'Daily':
                unit = 'DAY';
                if (uiSettings.DayType === 'Weekday') {
                    // Weekday is not supported in Autodesk so have to fake it
                    unit = 'WEEK';
                    unitCycle = 1;
                    runOn = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
                }
                break;
            case 'Weekly':
                unit = 'WEEK';
                runOn = [];
                for (const day of uiSettings.WeekdaySettings.filter(d => d.Checked)) {
                    runOn.push(day.Name as RunOn);
                }
                break;
            case 'Monthly':
                unit = 'MONTH';
                switch (uiSettings.MonthlyRecurrence) {
                    case "NumberedDay":
                        runOn = uiSettings.DayOfMonth;
                        break;
                    case "Weekday":
                        // Autodesk doesn't support this so fake it
                        runOn = this.TranslateMonthlyWeekday(uiSettings);
                        break;
                }
                break;
            default:
                throw Error('unsupported recurrence type');
        }

        return {
            startDate: startDate,
            endDate: endDate,
            runAtTime: runAtTime,
            runOn: runOn,
            unit: unit,
            unitCycle: unitCycle.toString()
        };
    }

    public static GetUiSchedule(autodesk: ScheduleData): RecurrenceSettings {
        const settings = new RecurrenceSettings(new Date(), 0);

        // Start and end date can be null while user is selecting their
        const startDate = autodesk.startDate == null
            ? new Date()
            : typeof (autodesk.startDate) == 'number'
                ? new Date(autodesk.startDate)
                : new Date(autodesk.startDate!.getFullYear(), autodesk.startDate!.getMonth(), autodesk.startDate!.getDate());
        const endDate = autodesk.endDate == null
            ? undefined
            : typeof (autodesk.endDate) == 'number'
                ? new Date(autodesk.endDate)
                : new Date(autodesk.endDate!.getFullYear(), autodesk.endDate!.getMonth(), autodesk.endDate!.getDate());
        const fullStartDate = autodesk.runAtTime == null
            ? startDate
            : this.ModifyDate(startDate, autodesk.runAtTime);
        const fullEndDate = endDate == null
            ? undefined
            : autodesk.runAtTime == null
                ? endDate
                : this.ModifyDate(endDate, autodesk.runAtTime);

        settings.StartDate = fullStartDate;
        settings.EndDate = fullEndDate!;
        settings.MaxRuns = 99999;
        settings.EndType = 'Date';
        settings.RepeatPeriod = +autodesk.unitCycle!;


        switch (autodesk.unit) {
            case 'DAY':
                settings.Recurrence = 'Daily';
                break;
            case 'WEEK':
                settings.Recurrence = 'Weekly';
                settings.DayType = 'Numbered';
                const daysChecked = autodesk.runOn as RunOn[];
                settings.WeekdaySettings[0].Checked = daysChecked.includes('Sunday');
                settings.WeekdaySettings[1].Checked = daysChecked.includes('Monday');
                settings.WeekdaySettings[2].Checked = daysChecked.includes('Tuesday');
                settings.WeekdaySettings[3].Checked = daysChecked.includes('Wednesday');
                settings.WeekdaySettings[4].Checked = daysChecked.includes('Thursday');
                settings.WeekdaySettings[5].Checked = daysChecked.includes('Friday');
                settings.WeekdaySettings[6].Checked = daysChecked.includes('Saturday');
                break;
            case 'MONTH':
                settings.Recurrence = 'Monthly';
                settings.MonthlyRecurrence = 'NumberedDay';
                settings.DayOfMonth = autodesk.runOn as number;
                break;
        }

        return settings;
    }

    public static GetSummary(uiSettings: RecurrenceSettings): string {
        const minutes = uiSettings.StartDate.getMinutes();
        const minuteDisplay = minutes === 0 ? '00' : minutes.toString();
        const hours = uiSettings.StartDate.getHours();
        let hourDisplay = hours.toString();
        let ampm = 'AM';
        if (hours === 0) {
            hourDisplay = '12';
        } else if (hours === 12) {
            ampm = 'PM';
        } else if (hours > 12) {
            hourDisplay = (hours - 12).toString();
            ampm = 'PM';
        }
        const timeSection = `at ${hourDisplay}:${minuteDisplay}${ampm} starting ${ConvertRunDate.Convert(uiSettings.StartDate)} and ending ${ConvertRunDate.Convert(uiSettings.EndDate)}`;

        switch (uiSettings.Recurrence) {
            case 'Daily':
                const dayPortion = uiSettings.RepeatPeriod > 1 ? 'days' : 'day';
                return `Every ${uiSettings.RepeatPeriod} ${dayPortion} ${timeSection}`;
            case 'Weekly':
                const weekPortion = uiSettings.RepeatPeriod > 1 ? 'days' : 'day';
                return `Every ${uiSettings.RepeatPeriod} ${weekPortion} on ${uiSettings.WeekdaySettings.filter(s => s.Checked).map(s => s.Name).join(', ')} ${timeSection}`;
            case 'Monthly':
                const monthPortion = uiSettings.RepeatPeriod > 1 ? 'months' : 'month';
                let dateEnding = 'th';
                const ending = uiSettings.DayOfMonth.toString()[uiSettings.DayOfMonth.toString().length - 1];
                switch (ending) {
                    case '1':
                        dateEnding = 'st';
                        break
                    case '2':
                        dateEnding = 'nd';
                        break;
                    case '3':
                        dateEnding = 'rd';
                        break;
                }
                return `Every ${uiSettings.RepeatPeriod} ${monthPortion} on the ${uiSettings.DayOfMonth}${dateEnding} ${timeSection}`;
        }

        return 'Error getting summary';
    }

    static TranslateMonthlyWeekday(uiSettings: RecurrenceSettings): number {
        let week: number;
        let day: number;
        switch (uiSettings.WeekOfMonth) {
            case 'first':
                week = 0;
                break;
            case 'second':
                week = 1;
                break;
            case 'third':
                week = 2;
                break;
            case 'fourth':
            case 'last':
                week = 3;
                break;
            default:
                week = 0;
        }
        switch (uiSettings.Day) {
            case 'Monday':
                day = 2;
                break;
            case 'Tuesday':
                day = 3;
                break;
            case 'Wednesday':
                day = 4;
                break;
            case 'Thursday':
                day = 5;
                break;
            case 'Friday':
                day = 6;
                break;
            default:
                day = 1;
                break;
        }

        return (week * 7) + day;
    }

    static GetRunTime(date: Date): string {
        const militaryHours = date.getHours();
        const baseMinute = date.getMinutes();

        let minute: number;
        let hour = militaryHours;

        if (baseMinute < 15) {
            minute = 0;
        } else if (baseMinute < 45) {
            minute = 30;
        } else {
            minute = 0;
            hour = militaryHours < 23 ? militaryHours + 1 : 0;
        }

        let ampm: string;
        if (hour === 0) {
            ampm = 'AM';
            hour = 12;
        } else if (hour < 12) {
            ampm = 'AM';
        } else if (hour === 12) {
            ampm = 'PM';
        } else {
            hour -= 12;
            ampm = 'PM';
        }

        return `${hour}:${minute === 0 ? '00' : minute} ${ampm}`;
    }

    static ModifyDate(date: Date, runTime: string): Date {
        const isPm = runTime.toLowerCase().includes('pm');

        const timeOnly = runTime.replace('AM', '').replace('PM', '').trim();

        const timeSplit = timeOnly.split(':');
        let hours = +timeSplit[0];
        const minutes = +timeSplit[1];

        if (isPm) {
            hours += 12;
        }

        return new Date(date.getTime() + hours * 60 * 60 * 1000 + minutes * 60 * 1000);
    }
}