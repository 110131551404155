import {
    DetailedUsageBase,
    IClient,
    StartCsvDownloadResult,
    UsageDownloadDto,
    UsageSortType,
    UsageSummaryBase
} from "../clients/Client";
import { UsageSummaryUI } from "../dataModel/UsageSummaryUI";
import { UsageDataTranslator } from "../dataModel/translators/UsageDataTranslator";
import { ClientProvider } from "../clients/ClientProvider";

export class UsageDataService {
    private _client: IClient = ClientProvider.Client;

    public StartCsvExport(): Promise<StartCsvDownloadResult> {
        return this._client.startCsvDownload();
    }

    public GetCsvDownload(id: string): Promise<UsageDownloadDto> {
        return this._client.getCsvDownload(id);
    }

    public GetUsageSummary(
        customerIds?: string[],
        userIds?: string[],
        jobIds?: string[],
        startTime?: Date,
        endTime?: Date
    ): Promise<UsageSummaryUI> {
        return this._client.getUsageSummary(false, undefined, undefined, customerIds, userIds, jobIds, startTime, endTime)
            .then((s: UsageSummaryBase) => UsageDataTranslator.TranslateUsageSummary(s.data!));
    }

    public GetUsageDetail(customerIds?: string[],
        userIds?: string[],
        jobIds?: string[],
        startTime?: Date,
        endTime?: Date,
        sort?: UsageSortType,
        sortAscending?: boolean,
        paginationToken?: string,
        paginationLimit?: number): Promise<DetailedUsageBase> {
        return this._client.getDetailedUsage(
            paginationLimit,
            paginationToken,
            customerIds,
            userIds,
            jobIds,
            startTime,
            endTime,
            sort,
            sortAscending);
    }
}