import { Task } from "../../dataModel/Task";
import { FilterItemData } from "../../dataModel/FilterItemData";

export class TasksState {
    filterOpen: boolean;
    filterOptions: FilterItemData[];
    filteredTasks: Task[];

    constructor() {
        this.filterOpen = false;
        this.filteredTasks = [];

        this.filterOptions = [];
    }
}