import { PAGE_TITLES, PAGES, PATHS } from "../Constants";
import Usage from "./Usage";
import Overview from "./Overview";
import Customers from "./Customers";
import Jobs from "./Jobs";
import Landing from "./Landing";
import Root from "./Root";
import Door from "./Door";
import { ChartLineIcon, ClipboardPencilIcon, HomeIcon, KeyIcon, PeopleIcon } from "@adsk/alloy-react-icon";
import { DocumentReportIcon, TaskListIcon } from "@adsk/alloy-react";
import { PageTypes, ScrollSections } from "../Enums";
import React from "react";

export const pages: {
    path: string,
    component: JSX.Element,
    title: string,
    exact: boolean,
    renderIcon: (p: any) => JSX.Element,
    uiVisible: boolean,
    scrollLocation: ScrollSections | undefined,
    type: PageTypes
}[] = [
    {
        path: PATHS[PAGES.ROOT],
        component: <Root />,
        title: PAGE_TITLES[PAGES.ROOT],
        exact: true,
        renderIcon: (p: any) => <HomeIcon {...p} />,
        uiVisible: false,
        scrollLocation: undefined,
        type: PageTypes.protected,
    },
    {
        path: PATHS[PAGES.DOOR],
        component: <Door />,
        title: PAGE_TITLES[PAGES.DOOR],
        exact: true,
        renderIcon: (p: any) => <KeyIcon {...p} />,
        uiVisible: false,
        scrollLocation: undefined,
        type: PageTypes.auth,
    },
    {
        path: PATHS[PAGES.LANDING],
        component: <Landing />,
        title: PAGE_TITLES[PAGES.LANDING],
        exact: true,
        renderIcon: (p: any) => <KeyIcon {...p} />,
        uiVisible: false,
        scrollLocation: undefined,
        type: PageTypes.auth,
    },
    {
        path: PATHS[PAGES.OVERVIEW],
        component: <Overview forceScrollSection={ScrollSections.tasks} />,
        title: PAGE_TITLES[PAGES.OVERVIEW],
        exact: true,
        renderIcon: (p: any) => <TaskListIcon {...p} />,
        uiVisible: true,
        scrollLocation: ScrollSections.tasks,
        type: PageTypes.protected,
    },
    {
        path: PATHS[PAGES.REPORT],
        component: <Overview forceScrollSection={ScrollSections.reports} />,
        title: PAGE_TITLES[PAGES.REPORT],
        exact: true,
        renderIcon: (p: any) => <DocumentReportIcon {...p} />,
        uiVisible: true,
        scrollLocation: ScrollSections.reports,
        type: PageTypes.protected,
    },
    {
        path: PATHS[PAGES.CUSTOMERS],
        component: <Customers />,
        title: PAGE_TITLES[PAGES.CUSTOMERS],
        exact: true,
        renderIcon: (p: any) => <PeopleIcon {...p} />,
        uiVisible: true,
        scrollLocation: undefined,
        type: PageTypes.admin,
    },
    {
        path: PATHS[PAGES.JOBS],
        component: <Jobs />,
        title: PAGE_TITLES[PAGES.JOBS],
        exact: true,
        renderIcon: (p: any) => <ChartLineIcon {...p} />,
        uiVisible: true,
        scrollLocation: undefined,
        type: PageTypes.admin,
    },
    {
        path: PATHS[PAGES.USAGE],
        component: <Usage />,
        title: PAGE_TITLES[PAGES.USAGE],
        exact: true,
        renderIcon: (p: any) => <ClipboardPencilIcon {...p} />,
        uiVisible: true,
        scrollLocation: undefined,
        type: PageTypes.admin,
    },
];
