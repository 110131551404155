import { UsageDataItem } from "../../dataModel/UsageDataItem";
import { UsageSummaryUI } from "../../dataModel/UsageSummaryUI";

export class UsageState {
    public dataItems: UsageDataItem[];
    public customerIds: string[];
    public userIds: string[];
    public jobIds: string[];
    public startDate: Date | null = null;
    public useStartDate: boolean;
    public endDate: Date | null = null;
    public useEndDate: boolean;
    public loadingCsv = false;
    public loading = false;
    public result: UsageSummaryUI | undefined;

    constructor() {
        this.jobIds = [];
        this.customerIds = [];
        this.userIds = [];
        this.dataItems = [];
        this.useStartDate = false;
        this.useEndDate = false;
    }
}