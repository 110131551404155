import React, {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router';
import {PAGES, PATHS} from '../Constants';
import {ObjectToQueryString} from '../Utility';
import {ClientProvider} from '../clients/ClientProvider';
import {CenteringContainer} from '../CommonStyledComponents';
import {ProgressRing} from '@adsk/alloy-react';

const authService = ClientProvider.AuthService;

const Door = () => {
    const history = useHistory();
    const location = useLocation();

    // @ts-ignore
    useEffect(() => {
        function attemptStoredLogin(): Promise<boolean> {
            return authService.AttemptStoredLogin()
                .then(result => {
                    if (result.Success) {
                        // @ts-ignore
                        const returnPath: any = location?.state?.from;
                        const navigation = returnPath == null || returnPath === '' || returnPath === '/'
                            ? `${PATHS[PAGES.ROOT]}${PATHS[PAGES.OVERVIEW]}`
                            : returnPath as string;
                        history.push(navigation);
                    }
                    return result.Success;
                });
        }

        function login(): void {
            // @ts-ignore
            const returnPath: any = location?.state?.from?.pathname;
            const stateParameter = {returnPath: returnPath};
            const queryParams: any = {
                response_type: 'code',
                client_id: process.env.REACT_APP_FORGE_CLIENT_ID,
                redirect_uri: process.env.REACT_APP_CALLBACK_URL,
                scope: 'user-profile:read data:read data:write data:create data:search',
                state: encodeURIComponent(JSON.stringify(stateParameter))
            };

            if (process.env.REACT_APP_USE_MOCK_DATA === '1') {
                history.push(`${PATHS[PAGES.LANDING]}?code=fake`);
            } else {
                window.location.href = `https://${process.env.REACT_APP_AUTODESK_BASE_ADDRESS}/authentication/v2/authorize?${ObjectToQueryString(queryParams)}`;
            }
        }

        attemptStoredLogin().then(r => {
            if (r) {
                return;
            }

            login();
        });
        // @ts-ignore
    }, [history, location?.state?.from]);

    return (
        <CenteringContainer>
            <ProgressRing size={'xlarge'}/>
        </CenteringContainer>
    );
};

export default Door;