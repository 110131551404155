import { Avatar, BasicButton, Flyout, TriangleDownIcon, TriangleUpIcon } from '@adsk/alloy-react';
import React, { useState } from 'react';
import { ClientProvider } from "../clients/ClientProvider";
import { BlueButton, FlexColumn, FlexRow } from '../CommonStyledComponents';
import { Theme } from "@adsk/alloy-react";

const authService = ClientProvider.AuthService;

const Profile = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    function exit(): void {
        authService.Logout();
        window.location.href = 'https://autodesk.com';
    }

    return (
        <Flyout
            show={menuOpen}
            onHide={() => setMenuOpen(false)}
            onShow={() => setMenuOpen(true)} placement={Flyout.PLACEMENTS.BOTTOM_END}
            content={(
                <FlexRow>
                    <img src={authService.CurrentUser?.profileImages?.sizeX40}
                         style={{ width: '40px', height: '40px', objectFit: "cover", marginRight: '2em' }}
                         alt={'User Profile'} />
                    <FlexColumn style={{ flex: '1' }}>
            <span
                style={Theme.typography.bodyMediumBold}>{authService.CurrentUser?.firstName} {authService.CurrentUser?.lastName}</span>
                        <span
                            style={{ marginTop: '0.2em', marginBottom: '1em' }}>{authService.CurrentUser?.email}</span>
                        <BlueButton style={{ width: '100%' }}
                                    onClick={exit}>Exit</BlueButton>
                    </FlexColumn>
                </FlexRow>
            )}>
            <BasicButton onClick={() => setMenuOpen(!menuOpen)}>
                {authService.CurrentUser != null && (
                    <>
                        <Avatar name={`${authService.CurrentUser.firstName} ${authService.CurrentUser.lastName}`}
                                size={Avatar.SIZES.MEDIUM_24}
                                imageUrl={authService.CurrentUser.profileImages?.sizeX40} />
                        <span
                            style={{ marginLeft: 10 }}>{`${authService.CurrentUser.firstName} ${authService.CurrentUser.lastName}`}</span></>
                )}
                {menuOpen && <TriangleUpIcon size={20} />}
                {!menuOpen && <TriangleDownIcon size={20} />}
            </BasicButton>
        </Flyout>
    );
};

export default Profile;