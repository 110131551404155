// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

import {
    JobBase,
    JobScheduleType,
    Schedule
} from '../../clients/Client';import {
    EmailPreferences,
    ExportReportLocationType,
    ExportReportOptions,
    FileDestinationNamingType
} from '../../clients/V2Client';
import { Task } from '../Task';
import { FileStructureTranslator } from './FileStructureTranslator';
import { CronTranslator } from './CronTranslator';

export class TaskTranslator {
    static GetTask(job: JobBase): Task {
        const task = new Task();
        task.Id = job.id;
        task.Name = job.name;
        task.CustomerId = job.customerId;
        task.UserId = job.userId;
        job.models!.forEach(apiModel => task.Models.push(FileStructureTranslator.GetFile(apiModel)));
        job.directories!.forEach(apiDirectory => task.Directories.push(FileStructureTranslator.GetDirectory(apiDirectory, false)));
        task.RawStatus = job.status!;
        task.IsPaused = job.schedule != null && job.schedule.isPaused!;
        switch (job.scheduleType) {
            case JobScheduleType.None:
                break;
            case JobScheduleType.OnceNow:
                task.Trigger = 'OnceNow';
                break;
            case JobScheduleType.OnceLater:
                task.Trigger = 'OnceLater';
                task.StartDate = job.nextRun == null || job.nextRun.startTime! < new Date()
                    ? new Date(new Date().getTime() + (15 * 60 * 1000))
                    : job.nextRun.startTime!;
                break;
            case JobScheduleType.Recurring:
                task.Trigger = 'Recurring';
                task.RecurrenceSettings = CronTranslator.GetSettings(job.schedule!);
                task.StartDate = job.schedule!.startDate!;
                break;
            case JobScheduleType.OnPublish:
                task.Trigger = 'OnPublish';
                break;
        }

        task.LastRun = job.lastRun?.startTime;
        task.NextRun = job.nextRun?.startTime;

        return task;
    }

    static GetEmailPreferences(task: Task): EmailPreferences {
        return new EmailPreferences({
            emailOnCompletion: task.EmailOnCompletion,
            attachExportFiles: task.AttachExportFiles
        });
    }

    static GetExportOptions(task: Task): ExportReportOptions {
        return new ExportReportOptions({
            locationProjectId: task.ExportProjectId,
            locationDirectoryId: task.ExportDirectoryId,
            locationType: task.ExportLocationType,
            destinationNamingType: task.ExportDestinationNaming
        });
    }

    static GetScheduleFromTask(task: Task): Schedule | undefined {
        switch (task.Trigger) {
            case 'OnceNow':
            case 'OnceLater':
            case 'OnPublish':
                return undefined;
            case 'Recurring':
                const end = task.RecurrenceSettings!.EndType === 'Date' ? task.RecurrenceSettings!.EndDate : undefined;
                const runs = task.RecurrenceSettings!.EndType === 'Occurrences' ? task.RecurrenceSettings!.MaxRuns : 0;
                return new Schedule({
                    cronString: CronTranslator.GetCronString(task.RecurrenceSettings!),
                    startDate: task.RecurrenceSettings!.StartDate,
                    endDate: end,
                    maxRuns: runs
                });
        }
    }
}
