import { JobDataEntry, UsageSortType } from "../../clients/Client";

export class JobsState {
    public dataItems: JobDataEntry[];
    public customerIds: string[];
    public userIds: string[];
    public jobIds: string[];
    public startDate: Date | null = null;
    public useStartDate: boolean;
    public endDate: Date | null = null;
    public useEndDate: boolean;
    public sortingField: UsageSortType = UsageSortType.Date;
    public sortAscending: boolean;
    public loading = false;
    public loadingMoreData = false;
    public hasMoreData = false;

    public showDetail = false;
    public detailObject: JobDataEntry | undefined;

    constructor() {
        this.jobIds = [];
        this.customerIds = [];
        this.userIds = [];
        this.dataItems = [];
        this.useStartDate = false;
        this.useEndDate = false;
        this.sortAscending = false;
    }
}