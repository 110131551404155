export enum InfoMenuActions {
    "multipleActions",
    "modalOpen",
    "modalContent",
}

export enum PageTypes {
    protected,
    auth,
    admin,
}

export enum ScrollSections {
    tasks = 'tasks',
    reports = 'reports'
}

export type TaskTrigger =
    'OnceNow' |
    'OnceLater' |
    'Recurring' |
    'OnPublish';

export enum TasksActions {
    "multipleActions",
    "filterOpen",
    "filteredTasks",
}

export enum ReportsActions {
    "multipleActions",
    "filterOpen",
    "filterOptions",
    "filteredReports",
    'checkedReports',
    'globalCheck',
    'showMultiFunctionWarning',
    'multiFunction'
}

export enum CustomersActions {
    "multipleActions",
    "customers",
    "editingCustomer",
    "showEdit",
    "showDelete",
    "editHeader",
    "loading",
}

export enum JobsActions {
    "multipleActions",
    "dataItems",
    "customerIds",
    "userIds",
    "jobIds",
    "startDate",
    "useStartDate",
    "endDate",
    "useEndDate",
    "sortingField",
    "sortAscending",
    "loading",
    "loadingMoreData",
    "hasMoreData",
    "showDetail",
    "detailObject",
}

export enum UsageActions {
    "multipleActions",
    "dataItems",
    "customerIds",
    "userIds",
    "jobIds",
    "startDate",
    "useStartDate",
    "endDate",
    "useEndDate",
    "loadingCsv",
    "loading",
    "result",
}

export enum TaskEditActions {
    "multipleActions",
    "task",
    "originalTask",
    "loading",
    "new",
    "duplicating",
    "selectedTab",
    "projects",
    "loadingProjects",
    "Saving",
    "showCancelConfirm",
    "expandedSource",
    "expandedDestination",
    'selectedProjectSource',
    'selectedProjectDestination',
    'recent',
    'loadingRecent',
    'loadingExportPath',
    'exportPath',
    'canSave',
}

export enum ReportDetailActions {
    'multipleActions',
    'report',
    'loading'
}

export enum CheckSetActions{
    'multipleActions',
    'loadingPublic',
    'loadingPrivate',
    'publicCheckSets',
    'privateCheckSets',
    'selected',
    'uploading',
}

export enum OptionsActions{
    'multipleActions',
    'exportExcel',
    'includeList',
    'combineReports',
    'exportHtml',
    'location',
    'naming',
    'email',
    'attachExports',
    'showLocationDialog',
    'selectedProject',
    'selectedDirectory'
}

export enum OverviewActions{
    'multipleActions',
    'taskLoading',
    'overallLoading',
    'reportLoading',
    'projectsAndHubsLoading',
    'tasks',
    'reports'
}