import { ExportReportLocationType } from "../clients/V2Client";
import { Constants } from "../Constants";

export class ConvertExportLocationType {
    public static Convert(value: ExportReportLocationType | undefined): string {
        switch (value) {
            case ExportReportLocationType.None:
            case undefined:
                return Constants.NoneString;
            case ExportReportLocationType.ModelDirectory:
                return 'Same folder as model';
            case ExportReportLocationType.ModelSubdirectory:
                return 'Subfolder in same folder as model';
            case ExportReportLocationType.OtherDirectory:
                return 'Single location for all exports';
        }
    }
}