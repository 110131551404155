import { IClient } from "../clients/Client";
import { ProjectUI } from "../dataModel/ProjectUI";
import { FileStructureTranslator } from "../dataModel/translators/FileStructureTranslator";
import { GetDirectoryPathResult } from "../dataModel/GetDirectoryPathResult";
import { DirectoryUI } from "../dataModel/DirectoryUI";
import { FindProjectItemRecursive } from "../Utility";
import { FileUI } from "../dataModel/FileUI";
import { ClientProvider } from "../clients/ClientProvider";

export class ModelService {
    private _client: IClient = ClientProvider.Client;

    GetRootDirectories(project: ProjectUI): Promise<boolean> {
        if (project.RootFolderArray != null && project.RootFolderArray.length > 0) {
            return Promise.resolve(true);
        }

        return this._client.listTopLevelDirectories(project.HubId, project.Id, false)
            .then(directories => {
                project.RootFolderArray = [];
                directories.forEach(directory => {
                    const dir = FileStructureTranslator.GetDirectory(directory, true);
                    dir.HubId = project.HubId;
                    project.RootFolderArray.push(dir);
                });

                return true;
            });
    }

    PopulateInitialStructure(project: ProjectUI): Promise<boolean> {
        if (project.RootFolderArray != null && project.RootFolderArray.length > 0) {
            return Promise.resolve(true);
        }
        return this.GetRootDirectories(project).then(async success => {
            if (!success) {
                console.error('Error getting root directories');
                return false;
            }

            const promises: Promise<boolean>[] = [];
            project.RootFolderArray.forEach(f => {
                promises.push(this.GetDirectoryContents(f).then(success2 => {
                    if (!success2) {
                        console.error('Error populating root contents');
                        return false;
                    }

                    return true;
                }));
            });

            await Promise.all(promises);

            return true;
        });
    }

    async PopulateToDirectories(project: ProjectUI, directoryIds: string[]): Promise<GetDirectoryPathResult[]> {
        const results: GetDirectoryPathResult[] = [];
        for (const id of directoryIds) {
            results.push(await this.PopulateToDirectory(project, id));
        }
        return results;
    }

    async PopulateToDirectory(project: ProjectUI, directoryId: string): Promise<GetDirectoryPathResult> {
        let currentDirectoryId: string = directoryId;
        let lastDirectory: DirectoryUI | null = null;
        const loaded: DirectoryUI[] = [];

        if (project.RootFolderArray.length === 0) {
            const gotRoot = await this.GetRootDirectories(project);
            if (!gotRoot) {
                throw Error('Failed to get root directories');
            }
        }

        loaded.forEach(f => loaded.push(f));

        while (true) {
            const existingLoaded = FindProjectItemRecursive(project, currentDirectoryId);
            let currentDirectory: DirectoryUI;
            if (existingLoaded != null && existingLoaded instanceof DirectoryUI) {
                currentDirectory = existingLoaded;
            } else {
                const currentDirectoryApi = await this._client.getDirectory(project.Id, currentDirectoryId);
                const isProjectFiles = currentDirectoryApi.parentId === project.RootDirectoryId;
                currentDirectory = FileStructureTranslator.GetDirectory(currentDirectoryApi, isProjectFiles);
            }

            currentDirectory.HubId = project.HubId;
            loaded.push(currentDirectory);
            if (lastDirectory != null) {
                lastDirectory.Parent = currentDirectory;
                currentDirectory.Subfolders.push(lastDirectory);
            }

            const existingRoot = project.RootFolderArray.find(f => f.Id === currentDirectory.Id);
            if (existingRoot != null) {
                return new GetDirectoryPathResult(true, loaded);
            }

            const rootParent = project.RootFolderArray.find(f => f.Id === currentDirectory.ParentId);
            if (rootParent != null || currentDirectory.ParentId === project.RootDirectoryId) {
                rootParent?.Subfolders.push(currentDirectory);
                currentDirectory.Parent = rootParent;
                return new GetDirectoryPathResult(true, loaded);
            }
            currentDirectoryId = currentDirectory.ParentId!;
            lastDirectory = currentDirectory;
        }
    }

    GetDirectoryContents(directory: DirectoryUI): Promise<boolean> {
        if (directory.AreItemsPopulated) {
            return Promise.resolve(true);
        }

        // TODO: How to do this in React
        // const delay = GetRandomNumber(800, 1200);
        // delayedRetry(delay, 5);

        return this._client.listFiles(directory.ProjectId, directory.Id, false, ['rvt'])
            .then(dto => {
                dto.directories!.sort((x, y) => x.name! > y.name! ? 1 : -1);
                dto.directories!.forEach(d => {
                    const dir = FileStructureTranslator.GetDirectory(d, false);
                    dir.HubId = directory.HubId;
                    dir.HubRegion = directory.HubRegion;
                    const projectMatchId = d.projectId!.replace('b.', '');
                    if (!dir.Name.includes(projectMatchId)) {
                        dir.Parent = directory;
                        directory.Subfolders.push(dir);
                    }
                });

                dto.files!.sort((x, y) => x.name! > y.name! ? 1 : -1);
                dto.files!.forEach(f => {
                    const file = FileStructureTranslator.GetFile(f);
                    file.HubId = directory.HubId;
                    file.HubRegion = directory.HubRegion;
                    directory.Models.push(file);
                });

                directory.AreItemsPopulated = true;

                return true;
            });
    }

    async PopulateSubdirectoryContents(directory: DirectoryUI): Promise<boolean> {
        if (directory.Subfolders == null || directory.Subfolders.length === 0) {
            return Promise.resolve(true);
        }

        const promises: Promise<boolean>[] = [];
        directory.Subfolders.forEach(d => promises.push(this.GetDirectoryContents(d)));
        await Promise.all(promises);

        return true;
    }

    GetRecentModels(): Promise<FileUI[]> {
        return this._client.listRecentFiles()
            .then(files => files.map(file => FileStructureTranslator.GetFile(file)));
    }
}