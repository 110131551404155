import React, { useEffect, useReducer } from 'react';
import { UsageDataService } from "../services/UsageDataService";
import { reducer } from "../components/reducers/JobsReducer";
import { JobsState } from "../components/states/JobsState";
import {
    BlueButton, CenteringContainer,
    ColumnLeft,
    ColumnRight,
    ContentWrapper,
    FlexColumn,
    FlexFill,
    FlexRow,
    FlexRowCentered
} from '../CommonStyledComponents';
import {
    ArrowRotateTwoIcon, Checkbox,
    DatePicker, Illustration,
    LoadMoreDataRow,
    Modal,
    ProgressRing,
    Table,
    Theme
} from "@adsk/alloy-react";
import Selector from "../components/Selector";
import { JobDataEntry, UsageSortType } from "../clients/Client";
import { ConvertRunDate } from "../converters/ConvertRunDate";
import { JobsActions } from "../Enums";
import IdInput from "../components/IdInput";
import { ConvertTextTruncation } from "../converters/ConvertTextTruncation";
import { GetErrorMessage, GetPropertyDisplayString } from "../Utility";

const service = new UsageDataService();
const pageSize = 30;
let paginationToken: string | undefined = undefined;

const Jobs = () => {
    const defaultState = new JobsState();

    const queryParams = new URLSearchParams(window.location.search);
    const customersRaw = queryParams.get('customers');
    const usersRaw = queryParams.get('users');
    const jobsRaw = queryParams.get('jobs');

    const customers = customersRaw == null ? [] : customersRaw.split(',');
    const users = usersRaw == null ? [] : usersRaw.split(',');
    const jobs = jobsRaw == null ? [] : jobsRaw.split(',');

    if (customers.length > 0 || users.length > 0 || jobs.length > 0) {
        defaultState.customerIds = customers;
        defaultState.userIds = users;
        defaultState.jobIds = jobs;
    }

    const [state, dispatch] = useReducer(reducer, defaultState);

    useEffect(() => {
        if (state.customerIds.length === 0 && state.userIds.length === 0 && state.jobIds.length === 0) {
            return;
        }

        getData();
    }, []);

    function getData(): void {
        const messages: string[] = [];

        if (state.useStartDate && state.startDate == null) {
            messages.push('Start date is not set');
        }

        if (state.useEndDate && state.endDate == null) {
            messages.push('End date is not set');
        }

        if (messages.length > 0) {
            alert(`You must fix the following before you can filter:\n\n${messages.join('\n')}`);
            return;
        }

        const customerIds = state.customerIds.length > 0 ? state.customerIds : undefined;
        const jobIds = state.jobIds.length > 0 ? state.jobIds : undefined;
        const userIds = state.userIds.length > 0 ? state.userIds : undefined;
        const start = state.useStartDate ? state.startDate : undefined;
        const end = state.useEndDate ? state.endDate : undefined;

        dispatch({
            type: JobsActions.multipleActions,
            payload: { loading: true, hasMoreData: false, loadingMoreData: false }
        });
        // TODO: How to do delayed retry
        // const delay = GetRandomNumber(800, 1200);
        service.GetUsageDetail(
            customerIds,
            userIds,
            jobIds,
            start ?? undefined,
            end ?? undefined,
            state.sortingField,
            state.sortAscending,
            undefined,
            pageSize).then(
            data => {
                paginationToken = data.isDone ? undefined : data.paginationData!.paginationToken;
                dispatch({
                    type: JobsActions.multipleActions, payload: {
                        loading: false,
                        dataItems: data.entries,
                        loadingMoreData: false,
                        hasMoreData: !data.isDone,
                    }
                });

                if (!data.isDone && data.entries!.length < pageSize) {
                    state.dataItems = data.entries!;
                    GetNextSet();
                }
            },
            er => {
                alert(GetErrorMessage(er, 'Filter Data'));
                dispatch({ type: JobsActions.loading, payload: false });
            }
        );
    }

    function GetNextSet(): void {
        if (paginationToken == null || paginationToken === '') {
            return;
        }

        const customerIds = state.customerIds.length > 0 ? state.customerIds : undefined;
        const jobIds = state.jobIds.length > 0 ? state.jobIds : undefined;
        const userIds = state.userIds.length > 0 ? state.userIds : undefined;
        const start = state.useStartDate ? state.startDate : undefined;
        const end = state.useEndDate ? state.endDate : undefined;

        dispatch({ type: JobsActions.loadingMoreData, payload: true });
        service.GetUsageDetail(
            customerIds,
            userIds,
            jobIds,
            start ?? undefined,
            end ?? undefined,
            state.sortingField,
            state.sortAscending,
            paginationToken,
            pageSize)
            .then(
                data => {
                    paginationToken = data.isDone ? undefined : data.paginationData!.paginationToken;

                    if (!data.isDone && data.entries!.length === 0) {
                        GetNextSet();
                    } else {
                        data.entries!.forEach(e => {
                            state.dataItems.push(e);
                        });

                        dispatch({
                            type: JobsActions.multipleActions, payload: {
                                dataItems: state.dataItems,
                                loadingMoreData: false,
                                hasMoreData: !data.isDone,
                            }
                        });
                    }
                },
                er => {
                    alert(GetErrorMessage(er, 'Filter Data'));
                    dispatch({ type: JobsActions.loading, payload: false });
                }
            );
    }

    function checkChanged(newState: boolean | 'indeterminate', box: string): void {
        let action: JobsActions;
        switch (box) {
            case 'start':
                action = JobsActions.useStartDate;
                break;
            case 'end':
                action = JobsActions.useEndDate;
                break;
            case 'sort':
                action = JobsActions.sortAscending;
                break;
            default:
                return;
        }
        dispatch({ type: action, payload: newState === true });
    }

    function onDateChange(newDate: Date | null | undefined, isStart: boolean): void {
        const action = isStart ? JobsActions.startDate : JobsActions.endDate;
        dispatch({ type: action, payload: newDate });
    }

    function onListChange(ids: string[], listType: string): void {
        let action: JobsActions;
        switch (listType) {
            case 'customer':
                action = JobsActions.customerIds;
                break;
            case 'user':
                action = JobsActions.userIds;
                break;
            case 'job':
                action = JobsActions.jobIds;
                break;
            default:
                return;
        }
        dispatch({ type: action, payload: ids })
    }

    function usageSortChange(sort: UsageSortType): void {
        dispatch({ type: JobsActions.sortingField, payload: sort });
    }

    const sortOptions = [
        { value: UsageSortType.Default, label: 'Default' },
        { value: UsageSortType.Date, label: 'Date' }
    ];

    const selectedOption = sortOptions.find(o => o.value === state.sortingField);

    return (
        <ContentWrapper>
            <FlexRow style={{ flex: 0 }}>
                <h1 style={Theme.typography.heading1}>Jobs</h1>
            </FlexRow>
            <FlexColumn>
                <FlexRow style={{ flex: 0 }}>
                    <ColumnLeft style={Theme.typography.bodyMedium}>Customer Ids</ColumnLeft>
                    <ColumnRight style={Theme.typography.bodyMedium}>
                        <IdInput ids={state.customerIds} onChange={ids => onListChange(ids, 'customer')} />
                    </ColumnRight>
                </FlexRow>
                <FlexRow style={{ flex: 0 }}>
                    <ColumnLeft style={Theme.typography.bodyMedium}>User Ids</ColumnLeft>
                    <ColumnRight style={Theme.typography.bodyMedium}>
                        <IdInput ids={state.userIds} onChange={ids => onListChange(ids, 'user')} />
                    </ColumnRight>
                </FlexRow>
                <FlexRow style={{ flex: 0 }}>
                    <ColumnLeft style={Theme.typography.bodyMedium}>Job Ids</ColumnLeft>
                    <ColumnRight style={Theme.typography.bodyMedium}>
                        <IdInput ids={state.jobIds} onChange={ids => onListChange(ids, 'job')} />
                    </ColumnRight>
                </FlexRow>
                <FlexRow style={{ flex: 0 }}>
                    <ColumnLeft style={Theme.typography.bodyMedium}>
                        <FlexRowCentered>
                            <Checkbox
                                checked={state.useStartDate}
                                onChange={value => checkChanged(value, 'start')} />
                            <label style={{marginLeft: '0.5em'}}>Start Date</label>
                        </FlexRowCentered>
                    </ColumnLeft>
                    <ColumnRight style={Theme.typography.bodyMedium}>
                        <DatePicker
                            date={state.startDate}
                            disabled={!state.useStartDate}
                            onChange={value => onDateChange(value as Date, true)} />
                    </ColumnRight>
                </FlexRow>
                <FlexRow style={{ flex: 0 }}>
                    <ColumnLeft style={Theme.typography.bodyMedium}>
                        <FlexRowCentered>
                            <Checkbox
                                checked={state.useEndDate}
                                onChange={value => checkChanged(value, 'end')} />
                            <label style={{marginLeft: '0.5em'}}>End Date</label>
                        </FlexRowCentered>
                    </ColumnLeft>
                    <ColumnRight style={Theme.typography.bodyMedium}>
                        <DatePicker
                            date={state.endDate}
                            disabled={!state.useEndDate}
                            onChange={value => onDateChange(value as Date, false)} />
                    </ColumnRight>
                </FlexRow>
                <FlexRow style={{ flex: 0 }}>
                    <ColumnLeft style={Theme.typography.bodyMedium}>Sorting</ColumnLeft>
                    <ColumnRight style={Theme.typography.bodyMedium}>
                        <FlexRow>
                            <Selector selected={selectedOption}
                                      items={sortOptions}
                                      onSelectionChange={s => usageSortChange(s?.value as UsageSortType ?? UsageSortType.Date)} />
                            <FlexRowCentered>
                                <Checkbox
                                    checked={state.sortAscending}
                                    style={{ marginLeft: '1em' }}
                                    onChange={value => checkChanged(value, 'sort')} />
                                <label style={{marginLeft: '0.5em'}}>Ascending</label>
                            </FlexRowCentered>
                        </FlexRow>
                    </ColumnRight>
                </FlexRow>
                <BlueButton style={{ marginBottom: '2em' }} onClick={getData}>
                    <FlexRowCentered>
                        <ArrowRotateTwoIcon style={{ marginRight: '0.5em' }} />
                        <span style={Theme.typography.labelMedium}>Refresh Data</span>
                    </FlexRowCentered>
                </BlueButton>
                <FlexFill>
                    {
                        !state.loading && state.dataItems.length > 0 &&
                        // @ts-ignore
                        <Table<DeckardJobDataEntry>
                            style={state.dataItems.length === 0 ? { height: 'inherit' } : {}}
                            isSortable={true}
                            columns={[
                                {
                                    accessor: 'jobRunScheduledStartTime',
                                    renderHeader: () => 'Date',
                                    renderCell: d => {
                                        return d.value == null ? null : ConvertRunDate.Convert(d.value);
                                    }
                                },
                                {
                                    accessor: 'jobId',
                                    renderHeader: () => 'Job ID',
                                },
                                {
                                    accessor: 'jobName',
                                    renderHeader: () => 'Job Name',
                                },
                                {
                                    accessor: 'runStatus',
                                    renderHeader: () => 'Status',
                                },
                                {
                                    accessor: 'user',
                                    renderHeader: () => 'User',
                                    renderCell: d => {
                                        const job = d.row.original as JobDataEntry;
                                        return `${job.jobRunDataEntry?.firstName} ${job.jobRunDataEntry?.lastName} (${job.jobRunDataEntry?.username})`;
                                    }
                                },
                                {
                                    id: 'details',
                                    renderCell: d => {
                                        const job = d.row.original as JobDataEntry;
                                        return <BlueButton onClick={() => {
                                            dispatch({
                                                type: JobsActions.multipleActions,
                                                payload: { showDetail: true, detailObject: job }
                                            });
                                        }}>Details</BlueButton>;
                                    }
                                },
                            ]}
                            data={state.dataItems}
                            renderLastRow={() => state.hasMoreData &&
                                <LoadMoreDataRow isLoading={state.loadingMoreData}
                                                 onLoad={async () => GetNextSet()} />} />
                    }
                    {
                        state.loading &&
                        <CenteringContainer>
                            <ProgressRing size={'large'} />
                        </CenteringContainer>
                    }
                    {
                        !state.loading && state.dataItems.length === 0 &&
                        <CenteringContainer style={{ flexDirection: 'column' }}>
                            <Illustration type={'pagesTextGrey'} height={200} width={200} />
                            <p style={Theme.typography.bodyLarge}>No Job Data to Display</p>
                        </CenteringContainer>
                    }
                </FlexFill>
            </FlexColumn>
            <Modal open={state.showDetail}>
                <Modal.Header>Item Detail</Modal.Header>
                <Modal.Body>
                    {
                        state.detailObject != null &&
                        Object.keys(state.detailObject).map(k => {
                            const propertyValue = GetPropertyDisplayString(state.detailObject, k);
                            const isLink = propertyValue != null && propertyValue.toLowerCase().startsWith('http');
                            const truncatedString = propertyValue == null ? '' : ConvertTextTruncation.Convert(propertyValue);
                            return (
                                <FlexRow>
                                    <ColumnLeft style={Theme.typography.bodyMediumBold}><span
                                        style={{ marginRight: '1em' }}>{k}</span></ColumnLeft>
                                    <ColumnRight style={Theme.typography.bodyMedium}>
                                        {isLink && <a href={propertyValue}>{truncatedString}</a>}
                                        {!isLink && <span>{truncatedString}</span>}
                                    </ColumnRight>
                                </FlexRow>
                            )
                        })
                    }
                </Modal.Body>
                <Modal.Footer>
                    <FlexRow style={{ justifyContent: 'end' }}>
                        <BlueButton style={{ marginLeft: '1em' }}
                                    onClick={() => dispatch({ type: JobsActions.showDetail, payload: false })}>
                            <FlexRowCentered>
                                <span style={Theme.typography.labelMedium}>Close</span>
                            </FlexRowCentered>
                        </BlueButton>
                    </FlexRow>
                </Modal.Footer>
            </Modal>
        </ContentWrapper>
    );
};

export default Jobs;