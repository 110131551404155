// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

import { DirectoryUI } from './dataModel/DirectoryUI';
import { ProjectUI } from './dataModel/ProjectUI';
import { Task } from './dataModel/Task';
import { JobStatusType, ProblemDetails } from './clients/Client';
import { ExportReportLocationType } from './clients/V2Client';
import { BIM360ItemBase } from "./dataModel/BIM360ItemBase";
import { ConvertRunDate } from "./converters/ConvertRunDate";
import { UsageDataItem } from "./dataModel/UsageDataItem";
import { TreeItem } from "./dataModel/TreeItem";
import { IReportStructureTreeItem } from "./dataModel/IReportStructureTreeItem";
import { Heading } from "./dataModel/Heading";
import { Section } from "./dataModel/Section";
import { FilterItemData } from "./dataModel/FilterItemData";
import { Constants } from "./Constants";
import { ConvertTaskStatus } from "./converters/ConvertTaskStatus";

export function GetDateWithOffset(modifyDays: number): Date {
    const currentTime = new Date().getTime();
    const date = new Date();

    date.setTime(currentTime + (modifyDays * 24 * 60 * 60 * 1000));

    return date;
}

export function GetRandomNumber(min: number, max: number) {
    const rawRandom = Math.random();
    return rawRandom * (max - min) + min;
}

export function array_move(arr: any[], old_index: number, new_index: number): void {
    while (old_index < 0) {
        old_index += arr.length;
    }
    while (new_index < 0) {
        new_index += arr.length;
    }
    if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
}

export function DownloadUrl(url: string, fileName: string): void {
    const a: any = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.style = 'display: none';
    a.click();
    a.remove();
}

export function OpenUrlInNewTab(url: string): void {
    const a: any = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    document.body.appendChild(a);
    a.style = 'display: none';
    a.click();
    a.remove();
}

export function ObjectToQueryString(object: any): string {
    const str = [];
    for (const p in object) {
        if (object.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(object[p]));
        }
    }

    return str.join('&');
}

export function GetErrorMessage(error: any, operation: string): string {
    let message = null;
    console.error(error);
    if (error instanceof ProblemDetails) {
        message = error.title;
    } else {
        if (error.hasOwnProperty('error') && error.error != null) {
            if (error.error instanceof ProblemDetails) {
                message = error.error.title;
            } else {
                // First priority - if error property is a non-blank string use that
                if (typeof error.error === 'string' && error.error !== '') {
                    message = error.error;
                } else if (error.error.hasOwnProperty('Message') && error.error.Message != null && error.error.Message !== '') {
                    // Next - if error is an object with a message property
                    message = error.error.Message;
                } else if (error.error.hasOwnProperty('message') && error.error.message != null && error.error.message !== '') {
                    // Next - if error is an object with a message property
                    message = error.error.message;
                }
            }
        }

        // If we don't have anything yet check for a message property directly on the error object
        if (message == null && error.hasOwnProperty('message') && error.message != null && error.message !== '') {
            message = error.message;
        } else if (message == null && error.hasOwnProperty('Message') && error.Message != null && error.Message !== '') {
            message = error.Message;
        }

        if (message == null) {
            if (error.hasOwnProperty('error') && error.error != null && error.error !== '') {
                // Last effort - check if it has an error object and use that
                message = error.error;
            } else {
                // Nothing was found, just tell them it's unknown
                message = 'Unknown Error';
            }
        }
    }

    return operation == null || operation === '' ? message : `${operation} Failed: ${message}`;
}

export function GetRecursiveFilePath(folder: DirectoryUI, pathDivider: string): string {
    if (folder.Parent == null) {
        return folder.Name;
    } else {
        const parentVal = GetRecursiveFilePath(folder.Parent, pathDivider);

        if (parentVal === '') {
            return folder.Name;
        } else {
            return `${parentVal}${pathDivider}${folder.Name}`;
        }
    }
}

export function FindDirectoryRecursive(parentDirectory: DirectoryUI, id: string): DirectoryUI | null {
    if (parentDirectory.Id === id) {
        return parentDirectory;
    }

    if (parentDirectory.Subfolders == null) {
        return null;
    }

    for (const child of parentDirectory.Subfolders) {
        const foundDirectory = FindDirectoryRecursive(child, id);
        if (foundDirectory != null) {
            return foundDirectory;
        }
    }

    return null;
}

export function DoesPathExist(path: string, projects: ProjectUI[]): boolean {
    if (path == null || path === '') {
        return false;
    }

    const pathSections = path.split('/');

    if (pathSections.length === 0) {
        return false;
    }

    const project = projects.find(p => p.Name === pathSections[0]);

    if (project == null || project.RootFolderArray == null || project.RootFolderArray.length === 0 || pathSections.length < 2) {
        return false;
    }

    const baseFolder = project.RootFolderArray.find(x => x.Name === pathSections[1]);

    if (baseFolder == null) {
        return false;
    }

    let currentFolder: DirectoryUI = baseFolder;

    for (let i = 2; i < pathSections.length; i++) {
        const folder = currentFolder.Subfolders.find(f => f.Name === pathSections[i]);

        if (folder == null) {
            return false;
        }

        currentFolder = folder;
    }

    return true;
}

export function ValidateExports(task: Task): string[] {
    const messages: string[] = [];
    if (task.ExportExcel && task.CombineExcel && task.Trigger === 'OnPublish') {
        messages.push('Combine Excel setting can not be used with publish triggers');
    }

    switch (task.ExportLocationType) {
        case ExportReportLocationType.ModelDirectory:
        case ExportReportLocationType.ModelSubdirectory:
            if (!task.ExportExcel || !task.CombineExcel) {
                return [];
            }

            // Check for models in multiple directories
            if (task.Directories != null && task.Directories.length > 1) {
                messages.push('Tasks using combined Excel export with multiple directories must use a single export location');
            }

            if (task.Directories != null && task.Directories.find(d => d.Recursive) != null) {
                messages.push('Tasks using combined Excel export with recursive directories must use a single export location');
            }

            if (task.Models != null && task.Models.length > 1) {
                const modelDirectoryId: string = task.Models[0].DirectoryId;
                if (task.Models.find(m => m.DirectoryId !== modelDirectoryId) != null) {
                    messages.push('Tasks using combined Excel export must have all models in the same folder or use a single export location');
                }
            }
            break;
        case ExportReportLocationType.OtherDirectory:
            if (task.ExportDirectoryId == null || task.ExportDirectoryId === '') {
                messages.push('The export directory id was not set');
            }
            if (task.ExportProjectId == null || task.ExportProjectId === '') {
                messages.push('The export directory project id was not set');
            }
            break;
    }

    return messages.length > 0 ? messages : [];
}

export function ValidateTrigger(task: Task): string[] {
    switch (task.Trigger) {
        case null:
        case undefined:
            return ['No trigger is selected'];
        case 'OnceNow':
        case 'OnPublish':
            return [];
        case 'OnceLater':
            if (task.StartDate < new Date()) {
                return ['Start date can not be in the past'];
            }
            return [];
        case 'Recurring':
            if (task.RecurrenceSettings!.Recurrence !== 'Weekly') {
                return [];
            }

            for (const day of task.RecurrenceSettings!.WeekdaySettings) {
                if (day.Checked) {
                    return [];
                }
            }
            return ['You must select at least one day to run checks'];
    }
}

export function FindProjectItemRecursive(project: ProjectUI, id: string): BIM360ItemBase | null {
    for (const directory of project.RootFolderArray) {
        const foundItem = FindBIM360ItemRecursive(directory, id, true, true);
        if (foundItem != null) {
            return foundItem;
        }
    }

    return null;
}

export function GetFile(url: string, fileName: string) {
    fetch(url)
        .then(response => response.blob())
        .then(blob => {
            const urlCreated = URL.createObjectURL(blob);
            DownloadUrl(urlCreated, fileName);
            URL.revokeObjectURL(urlCreated);
        });
}

/**
 * Determines if the page element with the given ID is the currently scrolled item in the parent
 * @param id The ID of the page element to find.
 * @param event The scroll event.  Should be passed from the parent element to find correct scroll.
 * @constructor
 */
export function PageElementIsCurrentScroll(id: string, event: any): boolean {
    const element = document.getElementById(id);

    if (element == null) {
        throw Error(`Could not find element with ID ${id}`);
    }

    const shellTop = event.currentTarget.getBoundingClientRect().top;
    const shellHeight = event.currentTarget.getBoundingClientRect().height;
    const divisionPoint = shellHeight / 3;
    const diff = element.getBoundingClientRect().top - shellTop;

    const bottomDiff = element.getBoundingClientRect().bottom - shellTop;
    return diff <= divisionPoint && bottomDiff > divisionPoint;
}

export function ScrollIntoView(id: string, parent: HTMLElement): void {
    const element = document.getElementById(id);

    if (element == null) {
        throw Error(`Could not find element with ID ${id}`);
    }

    setTimeout(() => {
        parent.scrollTo({
            behavior: 'smooth',
            top: element.offsetTop - parent.offsetTop
        });
    }, 100);
}

export function uniqueFilter(value: any, index: number, self: any[]) {
    return self.indexOf(value) === index;
}

export function uniqueDateDisplayFilter(value: Date, index: number, self: Date[]) {
    const dateDisplays = self.map(d => ConvertRunDate.Convert(d));
    return dateDisplays.indexOf(ConvertRunDate.Convert(value)) === index;
}

export function StringToList(value: string): string[] {
    const rawSplit = value.split(',');

    const list: string[] = [];
    rawSplit.forEach(e => {
        if (e == null || e.trim() === '') {
            return;
        }
        list.push(e.trim());
    });

    return list;
}

export function ListToString(values: string[]): string {
    return values.join(', ');
}

export function GetPropertyDisplayString(item: any, key: string): string | undefined {
    if (item == null) {
        return undefined;
    }
    const value: any = item[key];

    if (value == null) {
        return undefined;
    }

    if (value instanceof Object) {
        return JSON.stringify(value);
    }

    return value.toString();
}

export function GetFileNameFormattedDate(date: Date): string {
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDay().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const second = date.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}=${day} ${hour}:${minute}:${second}`;
}

export function GetTreeItems(items: IReportStructureTreeItem[]): TreeItem<IReportStructureTreeItem>[] {
    const allNodes: TreeItem<IReportStructureTreeItem>[] = [];

    for (const item of items) {
        const node = new TreeItem<IReportStructureTreeItem>(item.Id, item);
        if ((item instanceof Heading || item instanceof Section) && item.SubItems.length > 0) {
            node.children = GetTreeItems(item.SubItems);
        }
        allNodes.push(node);
    }

    return allNodes;
}

export function GetFileTreeItems(items: BIM360ItemBase[]): TreeItem<BIM360ItemBase>[] {
    const allNodes: TreeItem<BIM360ItemBase>[] = [];

    for (const item of items) {
        const node = new TreeItem<BIM360ItemBase>(item.Id, item);
        if (item instanceof DirectoryUI && item.SubItems.length > 0) {
            node.children = GetFileTreeItems(item.SubItems);
        }
        allNodes.push(node);
    }

    return allNodes;
}

export function AreAnyBranchesUnloaded(item: DirectoryUI): boolean {
    if (!item.AreItemsPopulated) {
        return true;
    }

    for (const folder of item.Subfolders) {
        if (AreAnyBranchesUnloaded(folder)) {
            return true;
        }
    }

    return false;
}

export function GetUsageTreeItems(items: UsageDataItem[]): TreeItem<UsageDataItem>[] {
    const allNodes: TreeItem<UsageDataItem>[] = [];

    for (const item of items) {
        const node = new TreeItem<UsageDataItem>(item.ID!, item);
        if (item.Children.length > 0) {
            node.children = GetUsageTreeItems(item.Children);
        }
        allNodes.push(node);
    }

    return allNodes;
}

export function TrimString(s: string, c: string): string {
    if (c === ']') {
        c = '\\]';
    }
    if (c === '^') {
        c = '\\^';
    }
    if (c === '\\') {
        c = '\\\\';
    }
    return s.replace(new RegExp(
        '^[' + c + ']+|[' + c + ']+$', 'g'
    ), '');
}

export function GetDefaultTaskFilterOptions(): FilterItemData[] {
    const statusOptions = [
        { value: '', label: Constants.NoFilterString },
        { value: JobStatusType.Scheduled, label: ConvertTaskStatus.Convert(JobStatusType.Scheduled) },
        { value: JobStatusType.Running, label: ConvertTaskStatus.Convert(JobStatusType.Running) },
        { value: JobStatusType.PostProcessing, label: ConvertTaskStatus.Convert(JobStatusType.PostProcessing) },
        { value: JobStatusType.Paused, label: ConvertTaskStatus.Convert(JobStatusType.Paused) },
        { value: JobStatusType.Completed, label: ConvertTaskStatus.Convert(JobStatusType.Completed) },
        {
            value: JobStatusType.PartiallyCompleted,
            label: ConvertTaskStatus.Convert(JobStatusType.PartiallyCompleted)
        },
        { value: JobStatusType.Error, label: ConvertTaskStatus.Convert(JobStatusType.Error) },
    ];

    return [
        {
            id: 'status',
            title: 'Status',
            selected: statusOptions[0],
            options: statusOptions,
        }
    ]
}

function FindBIM360ItemRecursive(
    parentDirectory: DirectoryUI,
    id: string, includeFiles: boolean,
    includeDirectories: boolean
): BIM360ItemBase | null {
    if (parentDirectory.Id === id && includeDirectories) {
        return parentDirectory;
    }

    if (includeFiles && parentDirectory.Models != null) {
        const file = parentDirectory.Models.find(f => f.Id === id);
        if (file != null) {
            return file;
        }
    }

    if (includeDirectories && parentDirectory.Subfolders != null) {
        for (const child of parentDirectory.Subfolders) {
            const foundDirectory = FindBIM360ItemRecursive(child, id, includeFiles, includeDirectories);
            if (foundDirectory != null) {
                return foundDirectory;
            }
        }
    }

    return null;
}
