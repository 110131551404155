import { CustomersActions } from "../../Enums";
import { CustomersState } from "../states/CustomersState";

export function reducer(state: CustomersState, action: { type: CustomersActions, payload: any }): CustomersState {
    switch (action.type) {
        case CustomersActions.loading:
            return { ...state, loading: action.payload };
        case CustomersActions.editHeader:
            return { ...state, editHeader: action.payload };
        case CustomersActions.editingCustomer:
            return { ...state, editingCustomer: action.payload };
        case CustomersActions.showEdit:
            return { ...state, showEditDialog: action.payload };
        case CustomersActions.showDelete:
            return { ...state, showDeleteConfirm: action.payload };
        case CustomersActions.multipleActions:
            return { ...state, ...action.payload };
        case CustomersActions.customers:
            return { ...state, customers: action.payload };
    }
}