import { ReportUI } from "../dataModel/ReportUI";
import {
  ExportReportOptions,
  ExportReportRequest,
  ExportResultDto,
  IV2Client,
  BatchDeleteResponse
} from "../clients/V2Client";
import { ClientProvider } from "../clients/ClientProvider";
import { ReportTranslator } from "../dataModel/translators/ReportTranslator";

export class ReportService {
  private _client: IV2Client = ClientProvider.V2Client;

  public GetReports(): Promise<ReportUI[]> {
    return this._client
      .listReportHistory()
      .then(r => r.items!.map(rpt => ReportTranslator.TranslateReport(rpt)));
  }

  public StartReportDownload(report: ReportUI, formats: string[] = ['csv']): Promise<ExportResultDto> {
    const options = new ExportReportOptions();
    options.exportFormats = formats;

    const body = new ExportReportRequest({
      reportId: report.Id,
      jobRunId: report.JobRunId,
      options: options
    });

    return this._client.startReportDownload(body);
  }

  public GetReportExportStatus(reportId: string): Promise<ExportResultDto> {
    return this._client.getExportDownloadStatus(reportId);
  }

  public DeleteReport(id: string): Promise<void> {
    return this._client.deleteReport(id);
  }

  public DeleteReports(ids: string[]): Promise<BatchDeleteResponse> {
    return this._client.deleteReports(ids);
  }
}