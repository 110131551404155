import React, { useEffect, useReducer, useState } from 'react';
import { useHistory } from "react-router";
import { reducer } from "./reducers/TasksReducer";
import { TasksState } from "./states/TasksState";
import { TasksActions } from "../Enums";
import { TaskService } from "../services/TaskService";
import { Constants, PAGES, PATHS } from "../Constants";
import { DefaultItem } from "@adsk/alloy-react-dropdown";
import { GetDefaultTaskFilterOptions, uniqueFilter } from "../Utility";
import { FilterItemData } from "../dataModel/FilterItemData";
import { Task } from "../dataModel/Task";
import { JobStatusType } from "../clients/Client";
import {
    ActionButton,
    BasicButton,
    CalendarIcon,
    CellProps,
    DocumentTwoIcon,
    FilterButton, IconButton,
    Illustration,
    Panel,
    PauseIcon,
    PencilIcon,
    ProgressRing,
    SearchField,
    StatusColorIndicator,
    Table,
    Theme,
    Tooltip,
    ArrowRotateTwoIcon,
    PlusIcon, OverflowTooltip,
} from "@adsk/alloy-react";
import {
    BlueButton,
    CenteringContainer,
    ContentScroller,
    ContentWrapper,
    FlexFill,
    FlexRow,
    FlexRowCentered
} from "../CommonStyledComponents";
import { ConvertTaskStatus } from "../converters/ConvertTaskStatus";
import { ConvertRunDate } from "../converters/ConvertRunDate";
import FilterItem from "./FilterItem";
import { GetListCell, getLoadingCell } from "../UiUtility";

const service = new TaskService();

const TaskList = (
    {
        tasks,
        loading,
        projectsAndHubsLoading,
        onReloadRequest,
        onDelete
    }: {
        tasks: Task[],
        loading: boolean,
        projectsAndHubsLoading: boolean,
        onReloadRequest?: () => void,
        onDelete?: (task: Task) => void
    }) => {
    const [state, dispatch] = useReducer(reducer, new TasksState());
    const history = useHistory();
    // Need to use 'useState' here because something is broken in the search field component with state binding
    const [search, setSearch] = useState('');

    useEffect(() => {
        dispatch({ type: TasksActions.filteredTasks, payload: tasks });
    }, [tasks]);

    useEffect(() => {
        if (projectsAndHubsLoading) {
            return;
        }

        const defaultProjects = { value: '', label: Constants.NoFilterString };
        const projectOptions = [defaultProjects];
        const dynamicProjects: DefaultItem[] = tasks
            .map(t => t.ProjectName ?? '-unknown-')
            .filter(uniqueFilter)
            .map(o => {
                return { value: o, label: o }
            });
        dynamicProjects.forEach(p => projectOptions.push(p));

        const defaultHubs = { value: '', label: Constants.NoFilterString };
        const hubOptions = [defaultHubs];
        const dynamicHubs: DefaultItem[] = tasks.map(t => t.HubName ?? '-unknown-')
            .filter(uniqueFilter)
            .map(o => {
                return { value: o, label: o }
            });
        dynamicHubs.forEach(p => hubOptions.push(p));

        const newFilterOptions: FilterItemData[] = [];

        GetDefaultTaskFilterOptions().forEach(o => {
            newFilterOptions.push(o);
        });

        newFilterOptions.push({
            id: 'hub',
            title: 'Source Hub',
            selected: defaultHubs,
            options: hubOptions,
        });

        newFilterOptions.push({
            id: 'project',
            title: 'Source Project',
            selected: defaultProjects,
            options: projectOptions,
        });

        dispatch({
            type: TasksActions.multipleActions, payload: {
                filteredTasks: tasks,
                filterOptions: newFilterOptions,
            }
        });
    }, [projectsAndHubsLoading, tasks]);

    function editTask(task: Task): void {
        history.push(`${PATHS[PAGES.ROOT]}${PATHS[PAGES.OVERVIEW]}/${task.Id}`);
    }

    function pauseTask(task: Task): void {
        service.ToggleTaskPause(task)
            .then(success => {
                if (!success) {
                    alert('Failed to toggle task pause');
                }
                dispatch({
                    type: TasksActions.multipleActions, payload: {
                        tasks: tasks,
                        filteredTasks: state.filteredTasks,
                    }
                });
            });
    }

    function duplicateTask(task: Task): void {
        history.push(`${PATHS[PAGES.ROOT]}${PATHS[PAGES.OVERVIEW]}/${task.Id}/duplicate`)
    }

    function searchChanged(e: any): void {
        setSearch(e);
        updateFilteredData(e);
    }

    function updateFilteredData(searchOverride?: string): void {
        const actualSearch = searchOverride ?? search;

        const filteredTasks = tasks.filter(t => {
            if (actualSearch != null && actualSearch !== '' && !t.Name?.toLowerCase().includes(actualSearch.toLowerCase())) {
                return false;
            }

            for (const filter of state.filterOptions) {
                if (filter.selected != null && filter.selected.value !== '') {
                    switch (filter.id) {
                        case 'status':
                            if (t.RawStatus !== filter.selected.value) {
                                return false;
                            }
                            break;
                        case 'project':
                            if (t.ProjectName !== filter.selected.value) {
                                return false;
                            }
                            break;
                        case 'hub':
                            if (t.HubName !== filter.selected.value) {
                                return false;
                            }
                            break;
                    }
                }
            }

            return true;
        });

        dispatch({ type: TasksActions.filteredTasks, payload: filteredTasks });
    }

    function handleAction(action: string[], task: Task): void {
        switch (action[0]) {
            case 'runNow':
                service.RunTask(task.Id!)
                    .then(jobRun => {
                        const updatedTask = tasks.find(t => t.Id === jobRun.jobId);
                        if (updatedTask == null) {
                            alert('Could not find task in UI, please refresh');
                            return;
                        }

                        // @ts-ignore - This is apparently not preferred, but it works for now...
                        updatedTask.RawStatus = JobStatusType[jobRun.status.toString()];
                    });
                break;
            case 'remove':
                service.DeleteTask(task)
                    .then(success => {
                        if (!success) {
                            alert('Failed to remove task');
                            return;
                        }

                        if (onDelete) {
                            onDelete(task);
                        }
                    });
                break;
        }
    }

    function renderStatusCell(d: CellProps<Task>): JSX.Element {
        let color = 'green';
        switch (d.value) {
            case JobStatusType.Scheduled:
                color = 'green';
                break;
            case JobStatusType.Completed:
                color = 'green';
                break;
            case JobStatusType.Paused:
                color = 'yellow';
                break;
            case JobStatusType.Running:
                color = 'green';
                break;
            case JobStatusType.Error:
                color = 'red';
                break;
            case JobStatusType.PartiallyCompleted:
                color = 'yellow';
                break;
            case JobStatusType.PostProcessing:
                color = 'green';
                break;
        }
        return (
            <FlexRowCentered>
                <StatusColorIndicator statusColor={color} style={{ marginRight: '0.25em' }} />
                <OverflowTooltip style={{ alignSelf: 'center', marginLeft: '0.5em' }}>
                    {ConvertTaskStatus.Convert(d.value)}
                </OverflowTooltip>
            </FlexRowCentered>
        )
    }

    function renderActionCell(d: CellProps<Task>): JSX.Element {
        return <FlexRow>
            <Tooltip content={'Edit this task'}>
                <BasicButton onClick={() => editTask(d.row.original)}>
                    <PencilIcon />
                </BasicButton>
            </Tooltip>
            <Tooltip
                content={
                    d.row.original.Trigger !== 'Recurring' && d.row.original.Trigger !== 'OnPublish'
                        ? undefined
                        : d.row.original.IsPaused ? 'Unpause this task' : 'Pause this task'
                }>
                <BasicButton onClick={() => pauseTask(d.row.original)}
                             style={
                                 d.row.original.Trigger !== 'Recurring' && d.row.original.Trigger !== 'OnPublish'
                                     ? { cursor: 'inherit' }
                                     : {}
                             }
                             disabled={d.row.original.Trigger !== 'Recurring' && d.row.original.Trigger !== 'OnPublish'}>
                    <div style={{ position: 'relative', width: '24px', height: '24px' }}>
                        <CalendarIcon style={{ position: 'absolute', left: '0', top: '0' }} />
                        {d.row.original.IsPaused && <div style={{
                            position: 'absolute',
                            right: '0',
                            bottom: '0',
                            width: '12px',
                            height: '12px',
                            background: 'white'
                        }}>
                            <PauseIcon style={{
                                width: '12px',
                                height: '12px',
                            }} />
                        </div>}
                    </div>
                </BasicButton>
            </Tooltip>
            <Tooltip content={'Duplicate this task'}>
                <BasicButton onClick={() => duplicateTask(d.row.original)}>
                    <DocumentTwoIcon />
                </BasicButton>
            </Tooltip>
            <ActionButton style={{ borderWidth: 0 }}
                          horizontal={false}
                          options={[
                              { key: 'remove', label: 'Remove' },
                              { key: 'runNow', label: 'Run Now' },
                          ]} onChange={e => handleAction(e, d.row.original)} />
        </FlexRow>
    }

    return (
        <Panel.Container>
            <ContentScroller>
                <ContentWrapper>
                    <h1 style={Theme.typography.heading1}>Tasks</h1>
                    <FlexRowCentered style={{ padding: '0.5em 0', flex: 0 }}>
                        <BlueButton onClick={() => history.push(`${PATHS[PAGES.ROOT]}${PATHS[PAGES.OVERVIEW]}/new`)}>
                            <FlexRowCentered>
                                <PlusIcon style={{ marginRight: '0.5em' }} />
                                <span style={Theme.typography.labelMedium}>New Task</span>
                            </FlexRowCentered>
                        </BlueButton>
                        <FlexFill />
                        <Tooltip content={'Refresh Tasks'}>
                            <IconButton
                                onClick={() => {
                                    if (onReloadRequest) {
                                        onReloadRequest();
                                    }
                                }}
                                renderIcon={() => <ArrowRotateTwoIcon />}
                                style={{ marginRight: '1em' }} />
                        </Tooltip>
                        <SearchField value={search} onChange={searchChanged} placeholder={'Search tasks...'}
                                     style={{ width: '300px', marginRight: '1em' }} />
                        <FilterButton
                            filtersOpen={state.filterOpen}
                            toggleFiltersPanel={() => dispatch({
                                type: TasksActions.filterOpen,
                                payload: !state.filterOpen
                            })}
                            clearFilters={() => null} />
                    </FlexRowCentered>
                    {
                        !loading &&
                        // @ts-ignore
                        <Table<Task> style={state.filteredTasks.length === 0 ? { height: 'inherit' } : {}}
                                     isSortable={true}
                                     columns={[
                                         {
                                             accessor: 'RawStatus',
                                             renderHeader: () => 'Status',
                                             width: 120,
                                             renderCell: d => renderStatusCell(d)
                                         },
                                         {
                                             accessor: 'Name',
                                             renderHeader: () => 'Name',
                                             renderCell: d => <OverflowTooltip>{d.value}</OverflowTooltip>
                                         },
                                         {
                                             accessor: 'HubName',
                                             renderHeader: () => 'Hub',
                                             renderCell: d => getLoadingCell(d)
                                         },
                                         {
                                             accessor: 'ProjectName',
                                             renderHeader: () => 'Project',
                                             renderCell: d => getLoadingCell(d)
                                         },
                                         {
                                             id: 'Models_Folders',
                                             renderHeader: () => 'Models/Folders',
                                             renderCell: d => GetListCell(d.row.original.Models.concat(d.row.original.Directories))
                                         },
                                         {
                                             accessor: 'RevitVersion',
                                             width: 100,
                                             renderHeader: () => 'Revit Version',
                                             renderCell: d =>
                                                 <OverflowTooltip>{ConvertRevitVersion.Convert(d.value)}</OverflowTooltip>
                                         },
                                         {
                                             accessor: 'Checkset',
                                             renderHeader: () => 'Checkset',
                                             renderCell: d => <OverflowTooltip>{d.value?.name}</OverflowTooltip>
                                         },
                                         {
                                             accessor: 'LastRun',
                                             width: 100,
                                             sortType: (a, b) => SortDatesWithPossibleNull(a.original.LastRun, b.original.LastRun),
                                             renderHeader: () => 'Last Run',
                                             renderCell: d =>
                                                 <OverflowTooltip>{ConvertRunDate.Convert(d.value)}</OverflowTooltip>
                                         },
                                         {
                                             accessor: 'NextRun',
                                             width: 100,
                                             sortType: (a, b) => SortDatesWithPossibleNull(a.original.NextRun, b.original.NextRun),
                                             renderHeader: () => 'Next Run',
                                             renderCell: d =>
                                                 <OverflowTooltip>{ConvertRunDate.Convert(d.value)}</OverflowTooltip>
                                         },
                                         {
                                             id: 'actions',
                                             width: 120,
                                             renderHeader: () => 'Actions',
                                             renderCell: d => renderActionCell(d)
                                         },
                                     ]} data={state.filteredTasks} />
                    }
                    {
                        loading &&
                        <CenteringContainer style={{ overflow: 'hidden' }}>
                            <ProgressRing size={'large'} />
                        </CenteringContainer>
                    }
                    {
                        !loading && tasks.length === 0 &&
                        <CenteringContainer style={{ flexDirection: 'column' }}>
                            <Illustration type={'folderEmptyGrey'} height={200} width={200} />
                            <p style={Theme.typography.bodyLarge}>You don't have any tasks yet</p>
                        </CenteringContainer>
                    }
                </ContentWrapper>
            </ContentScroller>
            <Panel
                title={'Filter Tasks'}
                open={state.filterOpen}
                style={{ height: 'auto' }}
                onClose={() => dispatch({ type: TasksActions.filterOpen, payload: false })}>
                <Panel.Body>
                    <div>
                        {state.filterOptions.map(o => {
                            return (
                                <FilterItem key={o.title} item={o} onSelectionChange={() => updateFilteredData()} />
                            )
                        })}
                    </div>
                </Panel.Body>
            </Panel>
        </Panel.Container>
    );
};

export default TaskList;