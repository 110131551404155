import React, { useEffect, useState } from 'react';
import { Task } from "../dataModel/Task";
import { ProjectUI } from "../dataModel/ProjectUI";
import { GetDirectoryPathResult } from "../dataModel/GetDirectoryPathResult";
import { ModelService } from "../services/ModelService";
import { FindProjectItemRecursive, GetErrorMessage, GetRecursiveFilePath } from "../Utility";
import { DirectoryUI } from "../dataModel/DirectoryUI";
import { CenteringContainer, LineWrapper, ValueWrapper } from '../CommonStyledComponents';
import { ProgressRing, Theme } from '@adsk/alloy-react';
import { ConvertAutodeskSchedule } from "../converters/ConvertAutodeskSchedule";
import { ExportReportLocationType } from "../clients/V2Client";
import { ConvertTrigger } from "../converters/ConvertTrigger";
import { ConvertRunDate } from "../converters/ConvertRunDate";
import { ConvertExportLocationType } from "../converters/ConvertExportLocationType";
import { ConvertFileDestinationNamingType } from "../converters/ConvertFileDestinationNamingType";

const fileService = new ModelService();

const SettingsSummary = ({ task, projects }: { task: Task | undefined, projects: ProjectUI[] }) => {
    const [sourcePaths, setSourcePaths] = useState<string[]>([]);
    const [loadingPaths, setLoadingPaths] = useState(false);

    useEffect(() => {
        let isMounted = true;
        if (task == null) {
            return;
        }

        const directoryIds: { projectId: string, directoryIds: string[] }[] = [];
        task.Directories.forEach(d => {
            const existing = directoryIds.find(e => e.projectId === d.ProjectId);
            if (existing == null) {
                directoryIds.push({ projectId: d.ProjectId, directoryIds: [d.Id] });
            } else {
                if (!existing.directoryIds.includes(d.Id)) {
                    existing.directoryIds.push(d.Id);
                }
            }
        });

        task.Models.forEach(m => {
            const existing = directoryIds.find(e => e.projectId === m.ProjectId);
            if (existing == null) {
                directoryIds.push({ projectId: m.ProjectId, directoryIds: [m.DirectoryId] });
            } else {
                if (!existing.directoryIds.includes(m.DirectoryId)) {
                    existing.directoryIds.push(m.DirectoryId);
                }
            }
        });

        if (task.ExportProjectId != null) {
            const existing = directoryIds.find(d => d.projectId === task.ExportProjectId);
            if (existing == null) {
                directoryIds.push({
                    projectId: task.ExportProjectId,
                    directoryIds: [task.ExportDirectoryId!]
                });
            } else {
                if (!existing.directoryIds.includes(task.ExportDirectoryId!)) {
                    existing.directoryIds.push(task.ExportDirectoryId!);
                }
            }
        }

        setLoadingPaths(true);
        const promises: Promise<GetDirectoryPathResult[]>[] = [];
        directoryIds.forEach(d => {
            const project = projects.find(p => p.Id === d.projectId);
            if (project == null) {
                return;
            }
            promises.push(fileService.PopulateToDirectories(project, d.directoryIds));
        });

        Promise.all(promises).then(results => {
            if (!isMounted) {
                return;
            }

            const allResults: GetDirectoryPathResult[] = [];
            results.forEach(rs => {
                rs.forEach(r => allResults.push(r));
            });

            if (allResults.find(r => !r.Success) != null) {
                alert('There were errors loading paths, some paths may not display correctly');
            }

            const sources: string[] = [];

            task.Directories.forEach(d => {
                const project = projects.find(p => p.Id === d.ProjectId);
                sources.push(`${project?.Name}/${GetRecursiveFilePath(d, '/')}`)
            });

            task.Models.forEach(m => {
                const project = projects.find(p => p.Id === m.ProjectId);
                const directory = project == null ? null : FindProjectItemRecursive(project, m.DirectoryId) as DirectoryUI;
                const directoryPath = directory == null ? '' : GetRecursiveFilePath(directory, '/');
                sources.push(`${project?.Name}/${directoryPath}/${m.Name}`);
            });

            setSourcePaths(sources);

            allResults.forEach(r => {
                task.Directories.forEach(d => {
                    const loaded = r.LoadedDirectories.find(l => l.Id === d.Id);
                    if (loaded != null) {
                        d.Name = loaded.Name;
                    }
                });

                if (task.ExportLocation != null) {
                    const loaded = r.LoadedDirectories.find(l => l.Id === task.ExportDirectoryId);
                    if (loaded != null) {
                        // TODO: Necessary?
                        // task.ExportDirectory.Name = loaded.Name;
                    }
                }
            });

            const destinationProject = projects.find(p => p.Id === task?.ExportProjectId);
            const populatedDirectory = destinationProject == null || task.ExportDirectoryId == null
                ? undefined
                : FindProjectItemRecursive(destinationProject, task.ExportDirectoryId) as DirectoryUI;
            task.ExportDirectoryId = populatedDirectory?.Id;
            task.ExportProjectId = populatedDirectory?.ProjectId;

            setLoadingPaths(false);
        }).catch(e => {
            alert(GetErrorMessage(e, 'Get file paths'));
            setLoadingPaths(false);
            const sources: string[] = [];
            task.Models.forEach(m => {
                sources.push(`${m.ProjectName}/[Error]/${m.Name}`);
            });
            setSourcePaths(sources);
        });

        return () => {
            isMounted = false;
        }
    }, [projects, task]);

    return (
        <>
            {(task == null || projects == null || projects.length <= 0) && (
                <CenteringContainer style={{ flex: 1 }}>
                    <ProgressRing size={'large'} />
                </CenteringContainer>
            )}
            {task != null && projects != null && projects.length > 0 && (
                <div>
                    <h2 style={Theme.typography.heading2}>Summary</h2>
                    <ValueWrapper>
                        <LineWrapper style={Theme.typography.bodyMediumBold}>Task Name</LineWrapper>
                        <LineWrapper style={Theme.typography.bodyMedium}>{task?.Name}</LineWrapper>
                    </ValueWrapper>
                    <ValueWrapper>
                        <LineWrapper style={Theme.typography.bodyMediumBold}>Source Files/Folders</LineWrapper>
                        <LineWrapper style={Theme.typography.bodyMedium}>
                            {loadingPaths && <ProgressRing />}
                            {!loadingPaths && sourcePaths.map(p => <LineWrapper key={p}>{p}</LineWrapper>)}
                        </LineWrapper>
                    </ValueWrapper>
                    <ValueWrapper>
                        <LineWrapper style={Theme.typography.bodyMediumBold}>Trigger</LineWrapper>
                        <LineWrapper style={Theme.typography.bodyMedium}>
                            {ConvertTrigger.Convert(task?.Trigger)}
                            {task.Trigger === 'OnceLater' && ` - ${ConvertRunDate.Convert(task?.StartDate, undefined, true)}`}
                            {task.Trigger === 'Recurring' && ` - ${task?.RecurrenceSettings == null ? '' : ConvertAutodeskSchedule.GetSummary(task.RecurrenceSettings)}`}
                        </LineWrapper>
                    </ValueWrapper>
                    <ValueWrapper>
                        <LineWrapper style={Theme.typography.bodyMediumBold}>Options</LineWrapper>
                        {task?.ExportExcel &&
                            <LineWrapper style={Theme.typography.bodyMedium}>Export
                                XLSX{task.ExportLists && ', include list elements'}{task.CombineExcel && ', combine all reports'}</LineWrapper>}
                        {task?.ExportHtml &&
                            <LineWrapper style={Theme.typography.bodyMedium}>Export HTML</LineWrapper>}
                        <LineWrapper style={Theme.typography.bodyMedium}>
                            {ConvertExportLocationType.Convert(task?.ExportLocationType)}
                            {task.ExportLocationType === ExportReportLocationType.OtherDirectory && ` - ${task.ExportLocation}`}
                        </LineWrapper>
                        <LineWrapper style={Theme.typography.bodyMedium}>
                            Destination file
                            naming: {ConvertFileDestinationNamingType.Convert(task?.ExportDestinationNaming)}
                        </LineWrapper>
                        {task?.EmailOnCompletion &&
                            <LineWrapper style={Theme.typography.bodyMedium}>
                                Email me when complete{task?.AttachExportFiles && ' and attach reports to email'}
                            </LineWrapper>}
                    </ValueWrapper>
                </div>
            )}
        </>
    );
};

export default SettingsSummary;