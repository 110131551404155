import { TaskTrigger } from "../Enums";
import { Constants } from "../Constants";


export class ConvertTrigger {
    public static Convert(value: TaskTrigger | undefined): string {
        switch (value) {
            case "OnceNow":
                return 'Once Now';
            case "OnceLater":
                return 'Once Later';
            case "Recurring":
                return 'Recurring';
            case "OnPublish":
                return 'On Publish';
            case undefined:
                return Constants.NoneString;
        }
    }
}