export class ConvertDate {
    public static Convert(value: Date | undefined): string {
        if (value == null) {
            return 'n/a';
        } else {
            return `${value.getFullYear()}-${this.twoDigit(value.getMonth())}-${this.twoDigit(value.getDay())}_${this.twoDigit(value.getHours())}-${this.twoDigit(value.getMinutes())}-${this.twoDigit(value.getSeconds())}`;
        }
    }

    static twoDigit(value: number): string {
        const int = value.toFixed(0).toString();
        return int.padStart(2, '0');
    }
}