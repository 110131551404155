// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

import { Project, ProjectType } from '../../clients/Client';
import { ProjectUI } from '../ProjectUI';
import { DirectoryUI } from '../DirectoryUI';

export class ProjectTranslator {
    static TranslateProject(apiProject: Project): ProjectUI {
        const uiProject = new ProjectUI(
            apiProject.id!,
            apiProject.name!,
            apiProject.rootDirectoryId!,
            apiProject.hubId!,
            apiProject.hubName!,
            apiProject.hubRegion!,
            apiProject.hubType!
        );

        uiProject.ImagePath = apiProject.imageUrl;
        uiProject.IsBIM360 = apiProject.id!.startsWith('b.');
        uiProject.ProjectType = apiProject.projectType ?? ProjectType.Unknown;

        if (!uiProject.IsBIM360) {

            // Create the default root folder
            const root = new DirectoryUI(uiProject.RootDirectoryId, 'Root', uiProject.Id);
            root.Id = uiProject.RootDirectoryId;
            root.HubId = uiProject.HubId;
            root.HubRegion = uiProject.HubRegion;
            root.ProjectType = uiProject.ProjectType;

            uiProject.RootFolderArray.push(root);
        }

        return uiProject;
    }
}
