export class TreeItem<T> {
    id: string;
    children: TreeItem<T>[];
    relatedObject: T;

    constructor(id: string, relatedObject: T) {
        this.id = id;
        this.children = [];
        this.relatedObject = relatedObject;
    }
}