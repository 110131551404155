import React, { useEffect, useReducer } from 'react';
import { useHistory, useParams } from "react-router";
import { TaskService } from "../services/TaskService";
import { ProjectService } from "../services/ProjectService";
import { reducer } from "../components/reducers/TaskEditReducer";
import { TaskEditState } from "../components/states/TaskEditState";
import { Task } from "../dataModel/Task";
import { ProjectUI } from "../dataModel/ProjectUI";
import { TaskEditActions } from "../Enums";
import { EDIT_TAB_IDS, EDIT_TAB_TITLES, EDIT_TABS, PAGES, PATHS } from "../Constants";
import {
    CreateJobRequestBase,
    JobScheduleType
} from "../clients/Client";
import {
    FileDestinationNamingType
} from "../clients/V2Client";
import {
    FindDirectoryRecursive,
    GetErrorMessage,
    GetRecursiveFilePath,
    ValidateExports,
    ValidateTrigger
} from "../Utility";
import {
    BlueButton,
    CenteringContainer,
    ContentWrapper,
    FlexColumn,
    FlexRow,
    FlexRowCentered
} from '../CommonStyledComponents';
import {
    ChevronLeftIcon,
    ChevronRightIcon, Modal,
    PlayIcon,
    ProgressRing, Tab,
    Tabs,
    Theme,
    Tooltip,
    XIcon
} from "@adsk/alloy-react";
import SettingsName from "../components/SettingsName";
import SettingsModels from "../components/SettingsModels";
import SettingsTrigger from "../components/SettingsTrigger";
import SettingsOptions from "../components/SettingsOptions";
import SettingsSummary from "../components/SettingsSummary";
import { ModelService } from "../services/ModelService";
import { FileStructureTranslator } from "../dataModel/translators/FileStructureTranslator";
import { TaskTranslator } from "../dataModel/translators/TaskTranslator";
import { DirectoryUI } from "../dataModel/DirectoryUI";

const service = new TaskService();
const projectService = new ProjectService();
const modelService = new ModelService();

const TaskEdit = () => {
    const params: { id: string, action: string } = useParams();
    const [state, dispatch] = useReducer(reducer, new TaskEditState());
    const history = useHistory();

    useEffect(() => {
        let isMounted = true;

        dispatch({
            type: TaskEditActions.multipleActions,
            payload: { Loading: true, LoadingProjects: true, LoadingRecent: true }
        });

        function tryPopulateLocationPath(): void {
            if (state.Task == null
                || state.Task.ExportProjectId == null
                || state.Task.ExportProjectId === ''
                || state.Projects == null
                || state.Projects.length === 0) {
                return;
            }
            const project = state.Projects.find(p => p.Id === state.Task!.ExportProjectId);

            if (project == null) {
                return;
            }

            dispatch({ type: TaskEditActions.loadingExportPath, payload: true });
            modelService.PopulateToDirectory(project, state.Task!.ExportDirectoryId!)
                .then(result => {
                    state.Task!.LoadingPath = false;
                    if (!result.Success) {
                        console.error('Error populating to directory');
                        return;
                    }

                    setLocationPath(project, state.Task!.ExportDirectoryId!);
                });
        }

        function setLocationPath(project: ProjectUI, directoryId: string): void {
            let directory: DirectoryUI | null = null;
            project.RootFolderArray.forEach(r => {
                const found = FindDirectoryRecursive(r, directoryId);
                if (found != null) {
                    directory = found;
                }
            });

            if (directory == null) {
                return;
            }

            const folderPath = GetRecursiveFilePath(directory, '/');
            state.Task!.ExportLocation = `${project.Name}/${folderPath}`;

            dispatch({
                type: TaskEditActions.multipleActions,
                payload: { loadingExportPath: false, exportPath: state.Task!.ExportLocation }
            });
        }

        function setSelectedProjects(task: Task | undefined, projects: ProjectUI[], additionalPayload: any): void {
            let projectSource: ProjectUI | undefined;
            if (task == null || (task.Directories.length === 0 && task.Models.length === 0)) {
                projectSource = projects.length > 0 ? projects[0] : undefined;
            } else {
                const taskBasedId = task.Directories.length > 0
                    ? task.Directories[0].ProjectId
                    : task.Models[0].ProjectId;
                projectSource = projects.find(proj => proj.Id === taskBasedId)
                    ?? (projects.length > 0 ? projects[0] : undefined);
            }

            let projectDestination: ProjectUI | undefined;
            projectDestination = task == null || task.ExportProjectId == null || task.ExportProjectId === ''
                ? projectSource
                : projects.find(proj => proj.Id === task.ExportProjectId) ?? projectSource;

            const isLoading = task == null || projects.length === 0;

            dispatch({
                type: TaskEditActions.multipleActions, payload: {
                    ...additionalPayload,
                    selectedProjectSource: projectSource,
                    selectedProjectDestination: projectDestination,
                    Loading: isLoading,
                }
            });
        }

        if (params.id.toLowerCase() === 'new') {
            const newTask = new Task();
            state.Task = newTask;

            dispatch({
                type: TaskEditActions.multipleActions,
                payload: { Task: newTask, Loading: false, IsNewTask: true }
            });
        } else {
            service.GetTask(params.id).then(task => {
                if (!isMounted) {
                    return;
                }

                if (params.action?.toLowerCase() === 'duplicate') {
                    task.Name = `${task.Name} Copy`;
                    task.Id = undefined;
                }

                state.Task = task;

                if (task.ExportDirectoryId != null && task.ExportDirectoryId.trim() !== '') {
                    tryPopulateLocationPath();
                }

                setSelectedProjects(task, state.Projects, {
                    Task: task,
                    OriginalTask: task.GetComparisonClone(),
                    IsDuplicating: params.action?.toLowerCase() === 'duplicate',
                });
            });
        }

        projectService.GetProjects().then(p => {
            if (!isMounted) {
                return;
            }

            state.Projects = p;

            setSelectedProjects(state.Task, p, {
                LoadingProjects: false,
                Projects: p,
            });
        });

        modelService.GetRecentModels().then(recent => {
            if (!isMounted) {
                return;
            }

            recent.forEach(r => r.LoadingProjectName = true);

            dispatch({ type: TaskEditActions.multipleActions, payload: { LoadingRecent: false, Recent: recent } });

            projectService.GetProjectAndHubNames(recent).then(success => {
                if (success) {
                    dispatch({
                        type: TaskEditActions.multipleActions,
                        payload: { LoadingRecent: false, Recent: recent }
                    });
                } else {
                    alert('There was an error getting project and hub names, some names may not display properly.');
                }
            });
        });

        return () => {
            isMounted = false;
        }
    }, [params.action, params.id]);

    function exportLocationChanged(directory: DirectoryUI): void {
        if (state.Task == null) {
            return;
        }
        state.Task.ExportProjectId = directory.ProjectId;
        state.Task.ExportDirectoryId = directory.Id;

        const project = state.Projects.find(p => p.Id === directory.ProjectId);

        if (project == null) {
            throw Error('Could not find project');
        }

        const location = GetRecursiveFilePath(directory, '/');
        state.Task!.ExportLocation = `${project.Name}/${location}`;

        dispatch({
            type: TaskEditActions.exportPath,
            payload: state.Task.ExportLocation
        });
    }

    function nextTab(): void {
        let newTab = null;
        switch (state.SelectedTab) {
            case EDIT_TAB_IDS[EDIT_TABS.NAME]:
                newTab = EDIT_TAB_IDS[EDIT_TABS.MODELS]
                break;
            case EDIT_TAB_IDS[EDIT_TABS.MODELS]:
                newTab = EDIT_TAB_IDS[EDIT_TABS.CHECKSET]
                break;
            case EDIT_TAB_IDS[EDIT_TABS.CHECKSET]:
                newTab = EDIT_TAB_IDS[EDIT_TABS.TRIGGER]
                break;
            case EDIT_TAB_IDS[EDIT_TABS.TRIGGER]:
                newTab = EDIT_TAB_IDS[EDIT_TABS.OPTIONS]
                break;
            case EDIT_TAB_IDS[EDIT_TABS.OPTIONS]:
                newTab = EDIT_TAB_IDS[EDIT_TABS.SUMMARY]
                break;
        }

        if (newTab == null) {
            return;
        }

        dispatch({ type: TaskEditActions.selectedTab, payload: newTab });
    }

    function previousTab(): void {
        let newTab = null;
        switch (state.SelectedTab) {
            case EDIT_TAB_IDS[EDIT_TABS.SUMMARY]:
                newTab = EDIT_TAB_IDS[EDIT_TABS.OPTIONS]
                break;
            case EDIT_TAB_IDS[EDIT_TABS.OPTIONS]:
                newTab = EDIT_TAB_IDS[EDIT_TABS.TRIGGER]
                break;
            case EDIT_TAB_IDS[EDIT_TABS.TRIGGER]:
                newTab = EDIT_TAB_IDS[EDIT_TABS.CHECKSET]
                break;
            case EDIT_TAB_IDS[EDIT_TABS.CHECKSET]:
                newTab = EDIT_TAB_IDS[EDIT_TABS.MODELS]
                break;
            case EDIT_TAB_IDS[EDIT_TABS.MODELS]:
                newTab = EDIT_TAB_IDS[EDIT_TABS.NAME]
                break;
        }

        if (newTab == null) {
            return;
        }

        dispatch({ type: TaskEditActions.selectedTab, payload: newTab });
    }

    function cancel(): void {
        history.push(`${PATHS[PAGES.ROOT]}${PATHS[PAGES.OVERVIEW]}`);
    }

    function save(): void {
        if (state.IsNewTask || state.IsDuplicating) {
            CreateNewTask();
        } else {
            UpdateTask();
        }
    }

    function CreateNewTask(): void {
        if (state.Task == null) {
            return;
        }

        let scheduleType: JobScheduleType = JobScheduleType.None;

        switch (state.Task.Trigger) {
            case 'OnceNow':
                scheduleType = JobScheduleType.OnceNow;
                break;
            case 'OnceLater':
                scheduleType = JobScheduleType.OnceLater;
                break;
            case 'Recurring':
                scheduleType = JobScheduleType.Recurring;
                break;
            case 'OnPublish':
                scheduleType = JobScheduleType.OnPublish;
                break;
        }

        const create = new CreateJobRequestBase({
            name: state.Task.Name,
            models: state.Task.Models.map(model => FileStructureTranslator.GetApiFileDto(model)),
            directories: state.Task.Directories.map(directory => FileStructureTranslator.GetApiDirectoryDto(directory)),
            schedule: TaskTranslator.GetScheduleFromTask(state.Task),
            jobScheduleType: scheduleType,
            runOnceDateTime: state.Task.Trigger === 'OnceLater' ? state.Task.StartDate : undefined
        });

        dispatch({ type: TaskEditActions.Saving, payload: true });
        service.CreateTask(create)
            .then(() => {
                dispatch({ type: TaskEditActions.Saving, payload: false });
                history.push(`${PATHS[PAGES.ROOT]}${PATHS[PAGES.OVERVIEW]}`);
            })
            .catch(error => {
                dispatch({ type: TaskEditActions.Saving, payload: false });
                console.error(error);
                alert(GetErrorMessage(error, 'Save task'));
            });
    }

    function UpdateTask(): void {
        if (state.Task == null || state.OriginalTask == null) {
            return;
        }
        const update = service.GetTaskUpdate(state.OriginalTask, state.Task);

        dispatch({ type: TaskEditActions.Saving, payload: true });
        service.UpdateTask(state.Task, update)
            .then(newTask => {
                dispatch({
                    type: TaskEditActions.multipleActions, payload: {
                        Saving: false,
                        Task: newTask,
                        OriginalTask: newTask.GetComparisonClone()
                    }
                });

                history.push(`${PATHS[PAGES.ROOT]}${PATHS[PAGES.OVERVIEW]}`);
            })
            .catch(error => {
                dispatch({ type: TaskEditActions.Saving, payload: false });
                console.error(error);
                alert(GetErrorMessage(error, 'Save task'));
            });
    }

    function ValidateFinish(task: Task): string[] {
        if (task == null) {
            return ['Task is null'];
        }

        const messages: string[] = [];

        if (task.Name == null || task.Name.trim() === '') {
            messages.push('Name is blank');
        }

        if (task.Models.length === 0 && task.Directories.length === 0) {
            messages.push('You have not selected any models or folders');
        }

        if ((task.ExportExcel || task.ExportHtml)
            && task.ExportDestinationNaming === FileDestinationNamingType.None) {
            messages.push('Please select a file naming option for exports');
        }

        const triggerValidation = ValidateTrigger(task);
        for (const message of triggerValidation) {
            messages.push(message);
        }

        const exportValidation = ValidateExports(task);
        for (const message of exportValidation) {
            messages.push(message);
        }

        return messages;
    }

    function getValidationTooltip(messages: string[]): string | undefined {
        return messages.length > 0
            ? `Your task is not complete and can't be saved!  Here are the issues: ${messages.join(', ')}.`
            : undefined;
    }

    function startCancel(): void {
        dispatch({ type: TaskEditActions.showCancelConfirm, payload: true });
    }

    function updateSaveAbility(): void {
        dispatch({
            type: TaskEditActions.canSave,
            payload: state.Task != null && ValidateFinish(state.Task).length === 0
        });
    }

    return (
        <ContentWrapper>
            {
                (state.Loading || state.Saving) &&
                <CenteringContainer style={{ flex: 1 }}>
                    <ProgressRing size={'large'} />
                </CenteringContainer>
            }
            {state.Task != null && !state.Loading && !state.Saving && (
                <>
                    <h1 style={Theme.typography.heading1}>{state.IsNewTask ? 'New Task' : 'Edit Task'}</h1>
                    <FlexRow style={{ marginBottom: '2em', marginTop: '1em', flex: 0 }}>
                        <Tooltip content={getValidationTooltip(ValidateFinish(state.Task))}>
                            <BlueButton onClick={save} disabled={!state.canSave}
                                        style={{ marginRight: '2em' }}>
                                <FlexRowCentered>
                                    <PlayIcon style={{ marginRight: '0.5em' }} />
                                    <span style={Theme.typography.labelMedium}>Save and run</span>
                                </FlexRowCentered>
                            </BlueButton>
                        </Tooltip>
                        <BlueButton onClick={previousTab}
                                    disabled={state.SelectedTab === EDIT_TAB_IDS[EDIT_TABS.NAME]}
                                    style={{ marginRight: '2em' }}>
                            <FlexRowCentered>
                                <ChevronLeftIcon style={{ marginRight: '0.5em' }} />
                                <span style={Theme.typography.labelMedium}>Back</span>
                            </FlexRowCentered>
                        </BlueButton>
                        <BlueButton onClick={nextTab}
                                    disabled={state.SelectedTab === EDIT_TAB_IDS[EDIT_TABS.SUMMARY]}
                                    style={{ marginRight: '2em' }}>
                            <FlexRowCentered>
                                <ChevronRightIcon style={{ marginRight: '0.5em' }} />
                                <span style={Theme.typography.labelMedium}>Next</span>
                            </FlexRowCentered>
                        </BlueButton>
                        <BlueButton onClick={startCancel}>
                            <FlexRowCentered>
                                <XIcon style={{ marginRight: '0.5em' }} />
                                <span style={Theme.typography.labelMedium}>Cancel</span>
                            </FlexRowCentered>
                        </BlueButton>
                    </FlexRow>
                    <Tabs active={state.SelectedTab}
                          style={{ flex: 1, display: 'flex', flexDirection: "column" }}
                          onChange={tab => dispatch({ type: TaskEditActions.selectedTab, payload: tab })}>
                        {Object.keys(EDIT_TABS).map((k) => {
                            return (
                                <Tab label={EDIT_TAB_TITLES[k]}
                                     tab={EDIT_TAB_IDS[k]}
                                     key={EDIT_TAB_IDS[k]}
                                     style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                    <FlexColumn style={{ paddingTop: '1em' }}>
                                        {state.SelectedTab === EDIT_TAB_IDS[EDIT_TABS.NAME] &&
                                            <SettingsName task={state.Task} onChange={updateSaveAbility} />}
                                        {state.SelectedTab === EDIT_TAB_IDS[EDIT_TABS.MODELS] &&
                                            <SettingsModels task={state.Task}
                                                            projects={state.Projects}
                                                            loadingProjects={state.LoadingProjects}
                                                            expandedIds={state.expandedSource}
                                                            selectedProject={state.selectedProjectSource}
                                                            recentModels={state.Recent}
                                                            loadingRecent={state.LoadingRecent}
                                                            onProjectSelected={p => dispatch({
                                                                type: TaskEditActions.selectedProjectSource,
                                                                payload: p
                                                            })}
                                                            onExpandedChanged={ids => dispatch({
                                                                type: TaskEditActions.expandedSource,
                                                                payload: ids
                                                            })}
                                                            onChanged={updateSaveAbility} />}
                                        {state.SelectedTab === EDIT_TAB_IDS[EDIT_TABS.TRIGGER] &&
                                            <SettingsTrigger task={state.Task} onChanged={updateSaveAbility} />}
                                        {state.SelectedTab === EDIT_TAB_IDS[EDIT_TABS.OPTIONS] &&
                                            <SettingsOptions
                                                task={state.Task}
                                                projects={state.Projects}
                                                loadingProjects={state.LoadingProjects}
                                                exportLocation={state.exportPath}
                                                loadingExportLocation={state.loadingExportPath}
                                                onLocationChange={exportLocationChanged} />}
                                        {state.SelectedTab === EDIT_TAB_IDS[EDIT_TABS.SUMMARY] &&
                                            <SettingsSummary task={state.Task} projects={state.Projects} />}
                                    </FlexColumn>
                                </Tab>
                            )
                        })}
                    </Tabs>
                </>
            )}
            <Modal open={state.showCancelConfirm}>
                <Modal.Header>Lose Changes?</Modal.Header>
                <Modal.Body>
                    <p style={Theme.typography.bodyMediumBold}>Canceling will cause you to lose any changes you have
                        made, are you
                        sure?</p>
                </Modal.Body>
                <Modal.Footer>
                    <FlexRow style={{ justifyContent: 'end' }}>
                        <BlueButton onClick={cancel}>
                            <FlexRowCentered>
                                <span style={Theme.typography.labelMedium}>Yes</span>
                            </FlexRowCentered>
                        </BlueButton>
                        <BlueButton style={{ marginLeft: '1em' }}
                                    onClick={() => dispatch({
                                        type: TaskEditActions.showCancelConfirm,
                                        payload: false
                                    })}>
                            <FlexRowCentered>
                                <span style={Theme.typography.labelMedium}>No</span>
                            </FlexRowCentered>
                        </BlueButton>
                    </FlexRow>
                </Modal.Footer>
            </Modal>
        </ContentWrapper>
    );
};

export default TaskEdit;