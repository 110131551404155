import axios, { AxiosInstance, InternalAxiosRequestConfig } from "axios";
import { AuthService } from "../services/AuthService";
import { ClientMock } from "../_mock/ClientMock";
import { Client, IClient } from "./Client";
import { IV2Client, V2Client } from "./V2Client";
import { V2ClientMock } from "../_mock/V2ClientMock";

export class ClientProvider {
  private static _axiosInstance: AxiosInstance;
  private static _clientMock: ClientMock;
  private static _client: IClient;
  private static _v2client: IV2Client;
  private static _v2clientMock: V2ClientMock;
  private static _authService: AuthService;

  private static _initialized = false;

  // noinspection JSUnusedLocalSymbols,JSVoidFunctionReturnValueUsed
  private static _initialize() {
    let client: IClient;
    if (process.env.REACT_APP_USE_MOCK_DATA === "1") {
      this._clientMock = new ClientMock();
      this._v2clientMock = new V2ClientMock();
      client = this._clientMock;
    } else {
      this._axiosInstance = axios.create();
      this._axiosInstance.interceptors.request.use(ClientProvider.CheckAddToken);

      this._client = new Client(
        process.env.REACT_APP_BASE_URL,
        this._axiosInstance
      );

      this._v2client = new V2Client(
        process.env.REACT_APP_BASE_URL,
        this._axiosInstance
      );
      client = this._client;
    }

    this._authService = new AuthService(client);
    this._initialized = true;
  }

  public static get Client(): IClient {
    if (!this._initialized) {
      this._initialize();
    }
    return process.env.REACT_APP_USE_MOCK_DATA === "1"
      ? this._clientMock
      : this._client;
  }

  public static get V2Client(): IV2Client {
    if (!this._initialized) {
      this._initialize();
    }
    return process.env.REACT_APP_USE_MOCK_DATA === "1"
      ? this._v2clientMock
      : this._v2client;
  }

  public static get AuthService(): AuthService {
    if (!this._initialized) {
      this._initialize();
    }
    return this._authService;
  }

  private static CheckAddToken(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig> {
    if (config.url?.toLowerCase().includes('/auth')
      || config.url?.toLowerCase().includes('/callback')
      || config.url?.toLowerCase().includes('/refresh')
      || config.url?.toLowerCase().includes('/reset/')
      || config.url?.toLowerCase().startsWith('https://s3.amazonaws.com')
    ) {
      // Does not require auth, don't get token
      return config;
    }

    return ClientProvider._authService.GetCurrentToken()
      .then(t => {
        if (t == null || t === '') {
          throw Error("No auth token");
        }

        if (config.headers != null) {
          config.headers!.Authorization = `Bearer ${t}`;
        }
        return config;
      });
  }
}