// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

export class Constants {
  static get NoFilterString(): string {
    return '-Any-';
  }

  static get MultipleSelectionString(): string {
    return '-Multiple Selected-';
  }

  static get NoneString(): string {
    return '-None-';
  }

  static get AdminRoutes(): string[] {
    return [
      '/customers',
      '/usage'
    ];
  }

  static get MaxJobUrlCharacters(): number {
    return 150;
  }
}

export const PAGES = {
    ROOT: "ROOT",
    DOOR: "DOOR",
    LANDING: "LANDING",
    OVERVIEW: "OVERVIEW",
    REPORT: "REPORT",
    CUSTOMERS: "CUSTOMERS",
    USAGE: "USAGE",
    JOBS: "JOBS",
};

export const PATHS = {
    [PAGES.ROOT]: "/app",
    [PAGES.DOOR]: "/door",
    [PAGES.LANDING]: "/landing",
    [PAGES.OVERVIEW]: "/tasks",
    [PAGES.REPORT]: "/report",
    [PAGES.CUSTOMERS]: "/customers",
    [PAGES.USAGE]: "/usage",
    [PAGES.JOBS]: "/jobs",
};

export const PAGE_TITLES = {
    [PAGES.ROOT]: "Root",
    [PAGES.DOOR]: "Door",
    [PAGES.LANDING]: "Landing",
    [PAGES.OVERVIEW]: "Tasks",
    [PAGES.REPORT]: "Reports",
    [PAGES.CUSTOMERS]: "Customers",
    [PAGES.USAGE]: "Usage",
    [PAGES.JOBS]: "Jobs",
};

export const EDIT_TABS = {
    NAME: "NAME",
    MODELS: "MODELS",
    CHECKSET: "CHECKSET",
    TRIGGER: "TRIGGER",
    OPTIONS: "OPTIONS",
    SUMMARY: "SUMMARY",
}

export const EDIT_TAB_TITLES = {
    [EDIT_TABS.NAME]: "Name",
    [EDIT_TABS.MODELS]: "Models",
    [EDIT_TABS.CHECKSET]: "Checkset",
    [EDIT_TABS.TRIGGER]: "Trigger",
    [EDIT_TABS.OPTIONS]: "Options",
    [EDIT_TABS.SUMMARY]: "Summary",
}

export const EDIT_TAB_IDS = {
    [EDIT_TABS.NAME]: "tbName",
    [EDIT_TABS.MODELS]: "tbModels",
    [EDIT_TABS.CHECKSET]: "tbCheckset",
    [EDIT_TABS.TRIGGER]: "tbTrigger",
    [EDIT_TABS.OPTIONS]: "tbOptions",
    [EDIT_TABS.SUMMARY]: "tbSummary",
}
