import { TaskEditState } from "../states/TaskEditState";
import { TaskEditActions } from "../../Enums";

export function reducer(
    state: TaskEditState,
    action: { type: TaskEditActions, payload: any }
): TaskEditState {
    switch (action.type) {
        case TaskEditActions.canSave:
            return { ...state, canSave: action.payload };
        case TaskEditActions.loadingExportPath:
            return { ...state, loadingExportPath: action.payload };
        case TaskEditActions.exportPath:
            return { ...state, exportPath: action.payload };
        case TaskEditActions.recent:
            return { ...state, Recent: action.payload };
        case TaskEditActions.loadingRecent:
            return { ...state, LoadingRecent: action.payload };
        case TaskEditActions.originalTask:
            return { ...state, OriginalTask: action.payload };
        case TaskEditActions.selectedProjectSource:
            return { ...state, selectedProjectSource: action.payload };
        case TaskEditActions.selectedProjectDestination:
            return { ...state, selectedProjectDestination: action.payload };
        case TaskEditActions.expandedSource:
            return { ...state, expandedSource: action.payload };
        case TaskEditActions.expandedDestination:
            return { ...state, expandedDestination: action.payload };
        case TaskEditActions.showCancelConfirm:
            return { ...state, showCancelConfirm: action.payload };
        case TaskEditActions.Saving:
            return { ...state, Saving: action.payload };
        case TaskEditActions.loadingProjects:
            return { ...state, LoadingProjects: action.payload };
        case TaskEditActions.projects:
            return { ...state, Projects: action.payload };
        case TaskEditActions.selectedTab:
            return { ...state, SelectedTab: action.payload };
        case TaskEditActions.new:
            return { ...state, IsNewTask: action.payload };
        case TaskEditActions.duplicating:
            return { ...state, IsDuplicating: action.payload };
        case TaskEditActions.loading:
            return { ...state, Loading: action.payload };
        case TaskEditActions.multipleActions:
            return { ...state, ...action.payload };
        case TaskEditActions.task:
            return { ...state, Task: action.payload };
    }
}