// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

export abstract class ReportSummarizable {
    TotalChecks: number;
    PassingChecks: number;
    FailingChecks: number;
    PassPercent: number;
    ReportingChecks: number;
    NotRunChecks: number;
    ErrorChecks: number;

    constructor() {
        this.TotalChecks = 0;
        this.PassingChecks = 0;
        this.FailingChecks = 0;
        this.PassPercent = 0;
        this.ReportingChecks = 0;
        this.NotRunChecks = 0;
        this.ErrorChecks = 0;
    }
}
