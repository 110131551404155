import React, { useCallback, useState } from 'react';
import { pages } from "../pages/Routes";
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import HeaderBar from "./HeaderBar";
import { Divider, Theme } from "@adsk/alloy-react";
import Nav from "./Nav";
import styled from "styled-components";
import NotFound from "../pages/NotFound";
import { FlexRow } from "../CommonStyledComponents";
import TaskEdit from "../pages/TaskEdit";
import { PAGES, PATHS } from '../Constants';
import ProtectedRoute from "./routing/ProtectedRoute";
import AdminRoute from "./routing/AdminRoute";
import { ClientProvider } from "../clients/ClientProvider";
import { PageTypes, ScrollSections } from "../Enums";
import Overview from "../pages/Overview";

const AppContainer = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const MainContainer = styled(FlexRow)`
    flex: 1;
    min-width: 0;
    min-height: 0;
`;

const ContentContainer = styled.div`
    flex: 1;
    min-width: 0;
    display: flex;
`;

const authService = ClientProvider.AuthService;

const MainContent = () => {
    const { path: appPath } = useRouteMatch();
    const [scrollSection, setScrollSection] = useState(ScrollSections.tasks);
    const [forceScrollSection, setForceScrollSection] = useState(ScrollSections.tasks);

    function getPageContent(page: {
        path: string,
        title: string,
        component: JSX.Element,
        exact: boolean,
        type: PageTypes
    }): any {
        switch (page.type) {
            case PageTypes.protected:
                return <ProtectedRoute
                    key={page.path}
                    path={`${appPath}${page.path}`}
                    component={page.component}
                    exact={page.exact} />;
            case PageTypes.admin:
                if (authService.CurrentUser?.isAdmin !== true) {
                    return undefined;
                }
                return <AdminRoute
                    key={page.path}
                    path={`${appPath}${page.path}`}
                    component={page.component}
                    exact={page.exact} />;
            case PageTypes.auth:
                return undefined;
        }
    }

    const taskScrollChanged = useCallback((selection: ScrollSections) => setScrollSection(selection), []);

    return (
        <AppContainer style={Theme.typography.bodyMedium}>
            <HeaderBar />
            <Divider />
            <MainContainer>
                <Nav
                    scrollSection={scrollSection}
                    onScrollChange={section => {
                        setScrollSection(section);
                        setForceScrollSection(section);
                    }
                    } />
                <ContentContainer>
                    <Switch>
                        {pages.filter(page => page.scrollLocation == null).map(page => getPageContent(page))}
                        <ProtectedRoute key={[PATHS[PAGES.OVERVIEW]]}
                                        path={`${appPath}${PATHS[PAGES.OVERVIEW]}`}
                                        exact={true}
                                        component={
                                            <Overview
                                                forceScrollSection={forceScrollSection}
                                                onScrollChanged={taskScrollChanged}
                                            />
                                        } />
                        <ProtectedRoute key={'/tasks/edit'}
                                        path={`${appPath}${PATHS[PAGES.OVERVIEW]}/:id/:action?`}
                                        component={<TaskEdit />} />
                        <Redirect to={`${PATHS[PAGES.ROOT]}${PATHS[PAGES.OVERVIEW]}`} from={''} />
                        <Route key={'catch'} path='*' exact={false} component={NotFound} />
                    </Switch>
                </ContentContainer>
            </MainContainer>
        </AppContainer>
    )
        ;
};

export default MainContent;