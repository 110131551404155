import { ExportReportLocationType, FileDestinationNamingType } from "../../clients/V2Client";
import { Task } from "../../dataModel/Task";
import { ProjectUI } from "../../dataModel/ProjectUI";
import { DirectoryUI } from "../../dataModel/DirectoryUI";

export class OptionsState {
    exportExcel: boolean;
    includeList: boolean;
    combineReports: boolean;
    exportHtml: boolean;
    location: ExportReportLocationType;
    naming: FileDestinationNamingType;
    email: boolean;
    attachExports: boolean;

    showLocationDialog: boolean = false;

    selectedProject: ProjectUI | undefined;
    selectedDirectory: DirectoryUI | undefined;

    constructor(task?: Task) {
        this.exportExcel = task?.ExportExcel ?? false;
        this.includeList = task?.ExportLists ?? false;
        this.combineReports = task?.CombineExcel ?? false;
        this.exportHtml = task?.ExportHtml ?? false;
        this.location = task?.ExportLocationType ?? ExportReportLocationType.ModelDirectory;
        this.naming = task?.ExportDestinationNaming ?? FileDestinationNamingType.AppendTimestamp;
        this.email = task?.EmailOnCompletion ?? false;
        this.attachExports = task?.AttachExportFiles ?? false;
    }
}