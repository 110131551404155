import { FileDestinationNamingType } from "../clients/V2Client";
import { Constants } from "../Constants";

export class ConvertFileDestinationNamingType {
    public static Convert(value: FileDestinationNamingType | undefined): string {
        switch (value) {
            case FileDestinationNamingType.None:
            case undefined:
                return Constants.NoneString;
            case FileDestinationNamingType.Overwrite:
                return 'Overwrite Existing Files';
            case FileDestinationNamingType.AppendTimestamp:
                return 'Append Time Stamp';
        }
    }
}