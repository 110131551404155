import styled from 'styled-components';
import { Button, Theme } from "@adsk/alloy-react";

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    min-width: 0;
    flex: 1;
`;

const FlexRowCentered = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const FlexRowFullCentered = styled(FlexRowCentered)`
    justify-content: center;
`;

const FlexFill = styled.div`
    flex: 1;
    min-height: 0;
`;

const ContentWrapper = styled(FlexColumn)`
    padding: 1em;
    min-width: 0;
    overflow: auto;
`;

const ContentScroller = styled.div`
    min-width: 0;
    overflow: auto;
    flex: 1;
    display: flex;
    margin: 0 1px;
`;

const CenteringContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
`;

const BlueButton = styled(Button)`
    background: ${Theme.colors.adskBlue500};
    color: white;
    align-self: start;
    min-width: 90px;

    &:disabled {
        background: ${Theme.colors._greyAlpha};
    }
`;

const ColumnLeft = styled.div`
    min-width: 10em;
    align-self: center;
    display: flex;
    margin-bottom: 1em;
`;

const ColumnRight = styled.div`
    flex: 1;
    display: flex;
    vertical-align: center;
    margin-bottom: 1em;
`;

const StructureTitles = styled.div`
    font-weight: bold;
    white-space: normal;
`;

const ValueWrapper = styled.div`
    margin-bottom: 2em;
`;

const LineWrapper = styled.div`
    margin: 0;
`;

const CheckSetItem = styled(FlexRowCentered)`
    padding: .25em 1em;
    cursor: pointer;

    &:hover {
        background: ${Theme.colors._greyAlpha};
    }
`;

const CheckSetIcon = styled.img`
    width: 2em;
    height: 2em;
    object-fit: contain;
`;

const CheckSetIconLarge = styled.img`
    width: 100px;
    height: 100px;
    object-fit: contain;
    margin: 1em;
`;

const BorderSection = styled.div`
    overflow-y: auto;
    border: 1px solid ${Theme.colors.charcoal300};
`;

export {
    FlexRow,
    FlexRowCentered,
    FlexRowFullCentered,
    FlexColumn,
    FlexFill,
    ContentWrapper,
    ContentScroller,
    BlueButton,
    CenteringContainer,
    ColumnLeft,
    ColumnRight,
    StructureTitles,
    ValueWrapper,
    LineWrapper,
    CheckSetItem,
    CheckSetIcon,
    CheckSetIconLarge,
    BorderSection,
}