// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

import { ReportSummarizable } from './ReportSummarizable';
import { FileReport } from './FileReport';
import { FileUI } from './FileUI';
import { Constants } from '../Constants';

export class ReportUI extends ReportSummarizable {
    Id: string;
    ReportDate: Date;
    Duration: number;

    Models: FileUI[];
    Files: FileReport[];

    FullReportUri: string;

    ExportingExcel = false;
    ExportingHtml = false;

    JobName: string;

    JobRunId: string;
    JobRunItemId: string;

    constructor(
        id: string,
        reportDate: Date,
        duration: number,
        reportUri: string,
        jobName: string,
        jobRunId: string,
        jobRunItemId: string
    ) {
        super();
        this.Id = id;
        this.ReportDate = reportDate;
        this.Duration = duration;
        this.Models = [];
        this.Files = [];
        this.FullReportUri = reportUri;
        this.JobName = jobName;
        this.JobRunId = jobRunId;
        this.JobRunItemId = jobRunItemId;
    }

    get ModelName(): string {
        if (this.Models == null || this.Models.length <= 0) {
            return 'Model name not set';
        }

        if (this.Models.length === 1) {
            return this.Models[0].Name;
        } else {
            return Constants.MultipleSelectionString;
        }
    }
}
